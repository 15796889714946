// images
import blogImg1 from "../images/blog/blog1.jpg";
import blogImg2 from "../images/blog/blog2.jpg";
import blogImg3 from "../images/blog/blog3.jpg";

import blogSingleImg1 from "../images/blog/blog3.jpg";
import blogSingleImg2 from "../images/blog/blog2.jpg";
import blogSingleImg3 from "../images/blog/blog1.jpg";
import blogSingleImg4 from "../images/profile/climate-change.png";
import blogSingleImg5 from "../images/profile/MIP-award.png";
import blogSingleImg6 from "../images/profile/charitywork.png";
import blogSingleImg7 from "../images/profile/nla.png";
import blogSingleImg8 from "../images/profile/PEEA.png";
import blogSingleImg9 from "../images/profile/CSRI.png";
import blogSingleImg10 from "../images/profile/humanityAward.png";
import blogSingleImg11 from "../images/profile/sitaraepakistan.png";
import blogSingleImg12 from "../images/profile/consumeraward.png";
import blogSingleImg13 from "../images/profile/school-one.png";
import blogSingleImg14 from "../images/profile/school-tow.png";
import blogSingleImg15 from "../images/profile/welfare-one.png";
import blogSingleImg16 from "../images/profile/welfare-two.png";

const blogs = [
  {
    id: "1",
    title: "Our Vision",
    slug: "our-vision",
    screens: blogImg1,
    description:
      "Vision 2040, spearheaded by Commander (R) Muhammad Zakir PN, Founder & Chairman ofCommander Builders (SMC-Pvt.) Ltd, aims to propel Pakistan into becoming a leading economic power. It envisions creating welfare cities like Commander City across Pakistan, fostering economic growth, education, healthcare, skill development, and scientific advancements. With milestones set for each era, including expanding living units, establishing educational and medical institutions, and nurturing businesses, the vision strives to create a prosperous, safe, and thriving society by 2040.",
    author: "CDR (R) Muhammad Zakir",
    authorTitle: "CEO",
    create_at: "22 MAY, 2024",
    blogSingleImg: blogSingleImg1,
    comment: "35",
    blClass: "format-standard-image",
    vision2028_2032: [
      "Commander City Establishment: Create a model community with 5,000 affordable housing units along Karachi-Hyderabad Motorway M-9.",
      "Inclusive Housing: Allocate 5% of units to widows, orphans, and disabled individuals for free living.",
      "Community Development: Provide amenities for 20,000-25,000 residents, fostering a safe and disciplined lifestyle.",
      "Sustainable Development: Align with United Nation’s Sustainable Development Goals for a sustainable environment.",
      "Job Creation: Generate 5,000 job opportunities for economic empowerment.",
      "Education and Skill Development: Establish educational institutes that prioritize character building and skill development.",
      "Healthcare Provision: Build a 200-bed hospital offering affordable care & issue medical cards for all residents.",
      "Social Welfare: Establish free orphanages, old homes, and gyne treatment wings.",
      "Business Opportunities: Promote entrepreneurial mindset and support residents with new business ventures within Commander City.",
      "Basic Needs Provision: Provide free restaurants and welfare stores to eliminate hunger and meet basic needs.",
    ],
    vision2032_2040: [
      "Massive Infrastructure Development: This era aims to undertake extensive expansion of Commander City and similar welfare cities across Pakistan. The goal is to accommodate over five million residents by constructing one million housing units within Commander City. Additionally, the plan includes allocating 50,000 houses for widows, orphans, and disabled persons for free living.",
      "Economic Growth and Asset Valuation: With the significant expansion of Commander City, the value of its commercial assets is projected to increase exponentially. Efforts will be made to ensure that the commercial assets reach a valuation of 60000 Billion Rupees by leveraging the success and popularity of the welfare city model.",
      "Scientific Research and Space Exploration: Pakistan aims to elevate its scientific research and development capabilities to new heights. This includes launching space missions from Commander City, showcasing the country's advancements in space exploration and technology on a global scale.",
      "Industrialization and Export: The industrial base within Commander City and across Pakistan will be strengthened further to position the country as one of the top exporters of quality goods and products. This initiative will contribute significantly to the economic growth and stability of Pakistan by generating revenue through exports.",
      "Education Excellence: Educational institutions within Commander City will continue to thrive, producing a significant number of Ph.D. holders, scientists, engineers, doctors, and other professionals. The focus will be on maintaining high standards of education and research to nurture talent and innovation.",
      "Entrepreneurship and Wealth Creation: A considerable emphasis will be placed on grooming young entrepreneurs within Commander City. By providing training and support, the aim is to create 150,000 skilled and successful businessmen who will drive economic growth and prosperity.",
      "Healthcare Advancements: Medical institutes and hospitals within Commander City will be further developed to ensure top-quality healthcare services. The objective is to elevate the average life expectancy of Pakistanis to rank among the top countries globally.",
      "Safety and Prosperity: Commander City aims to become the world's safest and most prosperous society, setting an example for other nations. Through effective governance, community engagement, and infrastructure development, the vision seeks to create a harmonious and prosperous environment for all residents.",
    ],
  },
  {
    id: "2",
    title: "Milestone Achieved",
    slug: "milestone-achieved",
    screens: blogImg2,
    description:
      "Vision 2040 has transformed from a dream into reality, by the grace of God Almighty. Starting in 2018 with no initial investment, Commander City has rapidly grown, boasting over 4000 member families today. Our controlled programs propel residents towards economic prosperity, while our operational school with business skill development has a hosting capacity of 2500 students. With assets worth billions and a trajectory guided seemingly by divine intervention, Commander City stands as a testament to vision and progress.",
    author: "Konal Biry",
    authorTitle: "Manager",
    create_at: "16 AUG, 2023",
    blogSingleImg: blogSingleImg2,
    comment: "80",
    blClass: "format-standard-image",
    // vision2028_2032: [
    //   "CCI Pure Vision for 2028-2032 includes initiatives such as...",
    //   "Detailed points for this vision.",
    // ],
    paragraph1: [
      "Commander (R) Muhammad Zakir, the visionary founder of Vision 2040 and Chairman of Commander Builders, is garnering global recognition for his outstanding contributions. His efforts have been acknowledged not only in Pakistan but also by esteemed organisations such as the United Nations and The Business Forum",
    ],
  },
  {
    id: "3",
    title: "Chairman's Profile",
    secondTitle: "HONORS AND AWARDS PRESENTED TO CDR (R) MUHAMMAD ZAKIR",
    thirdTitle:
      "Chairman Commander Builders is also Chief Administrator of Oxford Islamic Scientific School, managing for the last 20 years",
    forthTitle:
      "Motivational Lectures in different Universities to groom our Youth as ‘Welfare Project’ ",
    slug: "chairmans-profile",
    screens: blogImg3,
    description:
      "The Chairman has given his Vision 2040, main features include establishment of Low-Cost Township of approximately five thousand housing units initially on Karachi-Hyderabad Motorway M-9, on 'no profit basis'.",
    author: "CDR (R) Muhammad Zakir",
    authorTitle: "CEO",
    create_at: "16 AUG, 2024",
    blogSingleImg: blogSingleImg3,
    comment: "80",
    blClass: "format-standard-image",
    paragraph1: [
      "With all basic amenities which includes Schools, Universities, Hospitals and Industrial Units. Thereafter setting up similar townships in all major cities of the country, in line with UN’s Sustainable Development Goals i.e. Developing Sustainable Cities and Communities. His overall concept of housing / welfare is derived from the Quranic verses' Surah Zumar Ayat 10’ and he is a religiously pursuing implementation of the same, many renowned Pakistanis from various walks of life have joined him in his Vision-2040. Commander City Industries is another important milestone, achieved by the Chairman. Initially it has started with own production of daily used items and will expand the production in near future. His services to the society have been acknowledged in the form of various awards, some of which include; Best Builder Vision Award, Most Reliable Housing Scheme Award, Best Low-Cost Housing Scheme Award and NLA (Nation Leader Award) etc.",
    ],
    additionalImages: [
      {
        imgSrc: blogSingleImg4,
        caption:
          "Chairman Commander Builders Commander (R) Muhammad Zakir receiving Climate Change Award from President of Pakistan",
      },
      {
        imgSrc: blogSingleImg5,
        caption:
          "Chairman Commander Builders Commander (R) Muhammad Zakir receiving MIP Award from President of Pakistan",
      },
    ],
    additionalImages1: [
      {
        imgSrc: blogSingleImg6,
        caption:
          "Receiving shield of Honor for charity work from the President of Pakistan in the presence Governor of Sindh Mr. Kamran Tessori and  Moulana Bashir Ahmed farouqui",
      },
      {
        imgSrc: blogSingleImg7,
        caption:
          "NLA 2.0 (National Leaders Award) 2022 Best Low Cost Housing Scheme for the year 2022 Presented by the Honorable Mr. Nisar Khuhro Member Senate",
      },
    ],
    additionalImages2: [
      {
        imgSrc: blogSingleImg8,
        caption:
          "Pakistan Excellence Executive Award 2021 Presented by the Honorable Mr. Nisar Khuhro",
      },
      {
        imgSrc: blogSingleImg9,
        caption:
          "CSR International 2022 Award for Best Builder Vision of the Year",
      },
    ],
    additionalImages3: [
      {
        imgSrc: blogSingleImg10,
        caption:
          "Humanity Award 2022 Presented by the Honorable Governor Sindh Mr. Kamran Tessori",
      },
      {
        imgSrc: blogSingleImg11,
        caption:
          "Sn Award 2022, Most Credible Housing Scheme Award, Most Popular Housing Scheme Award Presented by the Honorable Mr. Saeed Ghani Information Minister Sindh",
      },
    ],
    imgSrc: blogSingleImg12,

    additionalImages4: [
      {
        imgSrc: blogSingleImg13,
      },
      {
        imgSrc: blogSingleImg14,
      },
    ],

    additionalImages5: [
      {
        imgSrc: blogSingleImg15,
      },
      {
        imgSrc: blogSingleImg16,
      },
    ],
  },

  // Add other blogs similarly
];

export default blogs;
