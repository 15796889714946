import React, { Fragment } from "react";
import Navbar2 from "../../components/Navbar2/Navbar2";
import Hero2 from "../../components/hero2/hero2";
import About2 from "../../components/about2/about2";
//import ServiceSection2 from "../../components/ServiceSection2/ServiceSection2";
import FunFact from "../../components/FunFact";
import TeamSection from "../../components/TeamSection/TeamSection";
import VideoSection from "../../components/VideoSection/VideoSection";
import FaqSection from "../../components/FaqSection/FaqSection";
import ContactSection from "../../components/ContactSection/ContactSection";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
//import Navbar from "../../components/Navbar/Navbar";
import { Helmet } from "react-helmet";

const HomePage2 = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Home - Commander Builders</title>
        <meta
          name="description"
          content="AT COMMANDER BUILDERS, OUR MISSION EXTENDS 
           BEYOND MERE CONSTRUCTION. WE ARE DEDICATED TO CREATING
           WELFARE SOCIETIES THAT NOT ONLY PROVIDE EXCEPTIONAL 
           LIVING SPACES BUT ALSO FOSTER OPPORTUNITIES FOR SKILL 
           DEVELOPMENT AND ECONOMIC EMPOWERMENT. OUR AIM IS TO 
           ENRICH COMMUNITIES AND ENHANCE LIVES THROUGH OUR 
           THOUGHTFUL PLANNING AND EXECUTION."
        />
        <link rel="canonical" href="http://localhost:3036/" />
      </Helmet>
      <Navbar2 />
      <div style={{ marginTop: "100px" }}>
        <Hero2 />
      </div>
      {/* <ServiceSection2 ptClass={"pt-100"} /> */}
      <About2 />
      <FunFact fClass={"style-two pt-165 pb-125 mb-0"} />
      <TeamSection />
      <VideoSection vclass={"pb-120"} />
      <FaqSection />
      <ContactSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default HomePage2;
