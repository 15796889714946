import React, { Fragment } from "react";
//import PageTitle from "../../components/pagetitle/PageTitle";
import BlogList from "../../components/BlogList/BlogList.js";
import Navbar2 from "../../components/Navbar2/Navbar2";
import Footer from "../../components/footer/Footer";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { Helmet } from "react-helmet";
import PageTitleVision from "../../components/pagetitle/ProjectTitleVision";

const BlogPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Our Vision - Commander Builders</title>
        <meta
          name="description"
          content="Vision 2040, spearheaded by Commander (R) 
          Muhammad Zakir PN, Founder & Chairman ofCommander Builders 
          (SMC-Pvt.) Ltd, aims to propel Pakistan into becoming a 
          leading economic power."
        />
        <link rel="canonical" href="http://localhost:3036/our-vision" />
      </Helmet>
      <Navbar2 hclass={"header-style-2"} />
      <PageTitleVision pageTitle={"Our Vision"} pagesub={"Vision 2040"} />
      <BlogList />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default BlogPage;
