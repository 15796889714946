import React from "react";
import { Link } from "react-router-dom";
import Lightbox from "yet-another-react-lightbox";
import Zoom from "yet-another-react-lightbox/plugins/zoom";
import "yet-another-react-lightbox/styles.css";
import Bg from "../../images/footer.png";
import pImg1 from "../../images/instagram/insta_one.png";
import pImg2 from "../../images/instagram/insta_two.png";
import pImg3 from "../../images/instagram/insta_three.jpg";
import pImg4 from "../../images/instagram/insta_four.jpg";
import pImg5 from "../../images/instagram/insta_five.jpg";
import pImg6 from "../../images/instagram/insta_six.jpg";
import CtaSection from "../CtaSection/CtaSection";

const images = [pImg1, pImg2, pImg3, pImg4, pImg5, pImg6];

const instagramLinks = [
  "https://www.instagram.com/p/C72FNBmoccs/?igsh=MWp5d2Y0c2E0eTRkag==",
  "https://www.instagram.com/p/C44c-lToc7j/?igsh=MXh1NWcxeDFvZTRqbQ==",
  "https://www.instagram.com/p/C32EISmooL3/?igsh=OTMxcGd5NndhZDU5",
  "https://www.instagram.com/p/C7_kI03IQF1/?igsh=MTk1aTVuN29wNDRlbw==",
  "https://www.instagram.com/p/C3nGRBlIeeG/?igsh=cm1vcTJzM20xNmNh",
  "https://www.instagram.com/p/C1v2tZOIQAi/?igsh=eW53a2k3Nmg0eGQx",
];

const Footer = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const [open, setOpen] = React.useState(false);

  return (
    <div>
      <CtaSection />
      <footer
        className="footer-section pt-220"
        style={{ backgroundImage: `url(${Bg})` }}
      >
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xl-6 col-lg-8">
              <div className="instagram-posts">
                {images.map((item, index) => (
                  <div className="instagram-item" key={index}>
                    <a
                      href={instagramLinks[index]}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <img src={item} alt={`Instagram ${index + 1}`} />
                      <div className="instagram-overlay">
                        <span>
                          <i className="fa fa-instagram"></i>
                        </span>
                      </div>
                    </a>
                  </div>
                ))}
              </div>
            </div>
            <div className="col-xl-6 col-lg-4">
              <div className="contact-widget">
                <h3>Contact</h3>
                <h6>
                  Find out all the ways to enjoy luxury residential life around
                  the world.
                </h6>
                <h6>
                  A: Commercial 5, Sector 40, Scheme 33, Malir Cantt Area,
                </h6>
                <h6>Karachi, Sindh Pakistan</h6>
              </div>
            </div>
          </div>
        </div>
        <div className="footer-bottom mt-55">
          <div className="container">
            <div className="bottom-inner">
              <div className="social-icons">
                <Link
                  onClick={ClickHandler}
                  to="https://www.youtube.com/channel/UC8wi6fwRHauXMdHxvIlYq0w"
                >
                  <i className="fa fa-youtube"></i>
                </Link>
                <Link
                  onClick={ClickHandler}
                  to="https://www.facebook.com/commanderbuilders/"
                >
                  <i className="fa fa-facebook"></i>
                </Link>
                <Link
                  onClick={ClickHandler}
                  to="https://www.instagram.com/commanderbuilders/?hl=en"
                >
                  <i className="fa fa-instagram"></i>
                </Link>
              </div>
              <div className="copyright">
                <p>
                  © Copyright 2024 by{" "}
                  <Link onClick={ClickHandler} to="/">
                    Commander Builders
                  </Link>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Lightbox
          open={open}
          close={() => setOpen(false)}
          slides={[
            { src: pImg1 },
            { src: pImg2 },
            { src: pImg3 },
            { src: pImg4 },
            { src: pImg5 },
            { src: pImg6 },
          ]}
          plugins={[Zoom]}
        />
      </footer>
    </div>
  );
};

export default Footer;
