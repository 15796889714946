import React, { Fragment } from "react";
import PageTitle from "../../components/pagetitle/PageTitle";
import Navbar2 from "../../components/Navbar2/Navbar2";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import About2 from "../../components/about2/about2";
import ServiceSection from "../../components/ServiceSection/ServiceSection";
//import FunFact from "../../components/FunFact";
import TeamSection from "../../components/TeamSection/TeamSection";
import VideoSection from "../../components/VideoSection/VideoSection";
//import About3 from "../../components/about2/about3";
import { Helmet } from "react-helmet";

const AboutPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>About - Commander Builders</title>
        <meta
          name="description"
          content="WELCOME TO COMMANDER BUILDERS, WHERE INTEGRITY, RELIABILITY,
           AND COMMITMENT ARE THE CORNERSTONES OF OUR PHILOSOPHY. WE 
           STAND AS A BEACON OF EXCELLENCE IN THE REALMS OF REAL ESTATE 
           DEVELOPMENT, ARCHITECTURE, AND CONSTRUCTION, DRIVEN BY THE 
           PRINCIPLES OF CREATIVITY, INNOVATION, AND UNCOMPROMISING 
           QUALITY"
        />
        <link rel="canonical" href="http://localhost:3036/about" />
      </Helmet>
      <Navbar2 />
      <PageTitle pageTitle={"About Us"} pagesub={"About Us"} />
      <ServiceSection ptClass={"pt-100"} />
      {/* <FunFact fClass={"style-two pt-165 pb-125 mb-0"} /> */}
      <About2 />
      {/* <About3 /> */}
      <TeamSection />
      {/* <ServiceSection ptClass={"pt-100"} /> */}
      <VideoSection />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default AboutPage;
