import React from "react";
import { Row, Col } from "antd";

const About2 = (props) => {
  return (
    <section
      className="about-section"
      style={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        fontFamily: "'Roboto', sans-serif", // Font change
      }}
    >
      <div className="container-fluid">
        <Row
          gutter={[16, 16]}
          justify="center"
          align="middle"
          style={{ minHeight: "100vh" }}
        >
          <Col xs={24} lg={12} className="text-center">
            <div className="wp-content about-content">
              <div className="section-title">
                <h2>
                  <span className="thin1">Our</span> Mission
                </h2>
                <p style={{ textTransform: "none" }}>
                  At Commander Builders, our mission extends beyond mere
                  construction. We are dedicated to creating welfare societies
                  that not only provide exceptional living spaces but also
                  foster opportunities for skill development and economic
                  empowerment. Our aim is to enrich communities and enhance
                  lives through our thoughtful planning and execution.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default About2;
