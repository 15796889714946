import React from "react";
import blogs from "../../api/blogs";
import { useParams } from "react-router-dom";
// import { Link } from "react-router-dom";
// import bImg1 from "../../images/blog/2.jpg";
// import bImg2 from "../../images/blog/3.jpg";
import "./../../css/style.css";

const BlogSingle = (props) => {
  const { slug } = useParams();
  const BlogDetails = blogs.find((item) => item.slug === slug);

  // const submitHandler = (e) => {
  //   e.preventDefault();
  // };

  const listStyle = {
    listStyleType: "disc",
    paddingLeft: "20px",
  };

  if (!BlogDetails) {
    return <div>Blog not found</div>;
  }

  return (
    <section className="blog-single mt-150 mb-50">
      <div className="container">
        {slug === "our-vision" ? (
          <>
            <div className="blog-image">
              <img src={BlogDetails.blogSingleImg} alt="Blog" />
              <span className="date">{BlogDetails.create_at}</span>
            </div>
            <h3 className="blog-title">{BlogDetails.title}</h3>

            <p>{BlogDetails.description}</p>

            <h3>Vision 2028-2032</h3>
            <div className="vision-section">
              <ul style={listStyle}>
                {BlogDetails.vision2028_2032.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            </div>
            <br />
            <h3>Vision 2032-2040</h3>
            <div className="vision-section">
              <ul style={listStyle}>
                {BlogDetails.vision2032_2040.map((item, idx) => (
                  <li key={idx}>{item}</li>
                ))}
              </ul>
            </div>
          </>
        ) : slug === "milestone-achieved" ? (
          <>
            <div className="blog-image">
              <img src={BlogDetails.blogSingleImg} alt="Blog" />
              <span className="date">{BlogDetails.create_at}</span>
            </div>
            <div className="blog-single-item">
              <div className="blog-single-title">
                <h3>{BlogDetails.title}</h3>
              </div>
              <p>{BlogDetails.description}</p>
              <p>{BlogDetails.paragraph1}</p>
            </div>
          </>
        ) : slug === "chairmans-profile" ? (
          <>
            <div className="blog-image">
              <img src={BlogDetails.blogSingleImg} alt="Blog" />
              <span className="date">{BlogDetails.create_at}</span>
            </div>
            <div className="blog-single-item">
              <div className="blog-single-title">
                <h3>{BlogDetails.title}</h3>
              </div>
              <p>{BlogDetails.description}</p>
              <p>{BlogDetails.paragraph1}</p>
              <div className="blog-single-title">
                <h3>{BlogDetails.secondTitle}</h3>
              </div>
              <div className="row mt-4">
                {BlogDetails.additionalImages && (
                  <div className="additional-images">
                    {BlogDetails.additionalImages.map((image, index) => (
                      <div className="blog-image" key={index}>
                        <img src={image.imgSrc} alt={`Award ${index + 1}`} />
                        <span className="caption">{image.caption}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="row mt-4">
                {BlogDetails.additionalImages1 && (
                  <div className="additional-images">
                    {BlogDetails.additionalImages1.map((image, index) => (
                      <div className="blog-image" key={index}>
                        <img src={image.imgSrc} alt={`Award ${index + 1}`} />
                        <span className="caption">{image.caption}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="row mt-4">
                {BlogDetails.additionalImages2 && (
                  <div className="additional-images">
                    {BlogDetails.additionalImages2.map((image, index) => (
                      <div className="blog-image" key={index}>
                        <img src={image.imgSrc} alt={`Award ${index + 1}`} />
                        <span className="caption">{image.caption}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="row mt-4">
                {BlogDetails.additionalImages3 && (
                  <div className="additional-images">
                    {BlogDetails.additionalImages3.map((image, index) => (
                      <div className="blog-image" key={index}>
                        <img src={image.imgSrc} alt={`Award ${index + 1}`} />
                        <span className="caption">{image.caption}</span>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div className="row mt-4">
                <div className="blog-image">
                  <img src={BlogDetails.imgSrc} alt="Blog" />
                  <span className="caption">
                    16th Consumer Choice Award 2022
                  </span>
                </div>
              </div>
              <div className="blog-single-title">
                <h3>{BlogDetails.thirdTitle}</h3>
              </div>
              <div className="row mt-4">
                {BlogDetails.additionalImages4 && (
                  <>
                    <div className="additional-images">
                      {BlogDetails.additionalImages4.map((image, index) => (
                        <div className="blog-image" key={index}>
                          <img src={image.imgSrc} alt={`Award ${index + 1}`} />
                        </div>
                      ))}
                    </div>
                  </>
                )}
              </div>
              <div className="blog-single-title">
                <h3>{BlogDetails.forthTitle}</h3>
              </div>
              <div className="row mt-4">
                {BlogDetails.additionalImages5 && (
                  <div className="additional-images">
                    {BlogDetails.additionalImages5.map((image, index) => (
                      <div className="blog-image" key={index}>
                        <img src={image.imgSrc} alt={`Award ${index + 1}`} />
                        {/* <span className="caption">{image.caption}</span> */}
                      </div>
                    ))}
                  </div>
                )}
              </div>
            </div>
          </>
        ) : (
          <div className="blog-single-item">
            <div className="blog-single-title">
              <h3>{BlogDetails.title}</h3>
            </div>
            <p>{BlogDetails.description}</p>
            <div className="blog-single-img">
              <img src={BlogDetails.blogSingleImg} alt="" />
            </div>
          </div>
        )}
        {/* <form onSubmit={submitHandler} className="comment-form">
          <h3 className="comment-title">Leave a Reply</h3>
          <div className="row clearfix">
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="text"
                  name="full_name"
                  id="full_name"
                  className="form-control"
                  required=""
                  placeholder="Name"
                />
              </div>
            </div>
            <div className="col-md-6">
              <div className="form-group">
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="form-control"
                  required=""
                  placeholder="Email"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <input
                  type="text"
                  name="time"
                  id="time"
                  className="form-control"
                  placeholder="Subject"
                />
              </div>
            </div>
            <div className="col-md-12">
              <div className="form-group">
                <textarea
                  name="message"
                  id="message"
                  className="form-control"
                  rows="8"
                  required=""
                  placeholder="Message"
                ></textarea>
              </div>
            </div>
            <div className="col-md-12 d-flex">
              <input type="checkbox" id="condition" className="mt-5" required />
              <label htmlFor="condition">
                Save my name, email, and website in this browser for the next
                time I comment.
              </label>
            </div>
            <div className="col-md-12">
              <button type="submit" className="theme-btn style-two mt-25">
                Send message
              </button>
            </div>
          </div>
        </form> */}
      </div>
    </section>
  );
};

export default BlogSingle;
