import React from "react";
import { Link } from "react-router-dom";
import Bg from "../../images/team/team-bg.png";
// import team1 from "../../images/team/team1.png";
// import team2 from "../../images/team/team2.png";
// import team3 from "../../images/team/team3.png";

const TeamSection = (props) => {
  return (
    <section
      className={`services-section2 rpt-300 pb-100 ${props.ptClass}`}
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="container">
        <div className="section-title text-white text-center mb-55">
          <h2>
            Our <span className="thin1">Vision</span>
          </h2>
          <br></br>
          {/* <h6>our team members</h6> */}
          <p className="vision" style={{ textTransform: "none" }}>
            Vision 2040, spearheaded by Commander (R) Muhammad Zakir PN, Founder
            & Chairman of Commander Builders (SMC-Pvt.) Ltd, aims to propel
            Pakistan into becoming a leading economic power. It envisions
            creating welfare cities like Commander City across Pakistan,
            fostering economic growth, education, healthcare, skill development,
            and scientific advancements. With milestones set for each era,
            including expanding living units, establishing educational and
            medical institutions, and nurturing businesses, the vision strives
            to create a prosperous, safe, and thriving society by 2040.
          </p>
          <div data-swiper-parallax="500" className="slide-btns">
            <Link to="/blog-single/our-vision" className="theme-btn">
              Discover More
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TeamSection;

// import React from "react";
// import Bg from "../../images/team/team-bg.png";
// // import team1 from "../../images/team/team1.png";
// // import team2 from "../../images/team/team2.png";
// // import team3 from "../../images/team/team3.png";

// const TeamSection = (props) => {
//   return (
//     <section
//       className={`services-section2 rpt-200 pb-100 ${props.ptClass}`}
//       style={{ backgroundImage: `url(${Bg})` }}
//     >
//       <div className="container">
//         <div className="section-title mb-10">
//           <h6>Our Vision</h6>
//           <br></br>
//           <p>
//             <span className="thin">
//               Welcome to Commander Builders, where integrity, reliability, and
//               commitment are the cornerstones of our philosophy. We stand as a
//               beacon of excellence in the realms of real estate development,
//               architecture, and construction, driven by the principles of
//               creativity, innovation, and uncompromising quality. As we continue
//               our journey, we are proud to compile a list of remarkable
//               accomplishments that testify to our commitment to excellence. Join
//               us as we pave the way for a brighter, more sustainable future in
//               the world of real estate and construction.
//             </span>
//           </p>
//         </div>
//       </div>
//     </section>
//   );
// };

// export default TeamSection;
