import React from "react";
import { Link } from "react-router-dom";
import Services from "../../api/Services";
import Bg from "../../images/services/service-bg.jpg";

const ServiceSection2 = (props) => {
  const ClickHandler = () => {
    window.scrollTo(10, 0);
  };

  const truncateText = (text, maxLength) => {
    if (text.length <= maxLength) {
      return text;
    }
    return text.slice(0, maxLength) + "...";
  };

  return (
    <section
      className="services-section pt-150 rpt-100 pb-70"
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="container">
        <div className="row">
          <div className="section-title mb-35">
            <h2>Skill Development & Economic Empowerment</h2>
          </div>
          {Services.slice(0, 2).map((service, Sitem) => (
            <div className="col-lg-4 col-md-6 col-12 d-flex">
              <div className="service-item flex-fill" key={Sitem}>
                <span className="number">0{service.Id}</span>
                <img className="images" src={service.sImg} alt="Icon" />
                <h3>
                  <Link
                    onClick={ClickHandler}
                    to={`/service-single/${service.slug}`}
                  >
                    {service.sTitle}
                  </Link>
                </h3>
                <p>{truncateText(service.description, 60)}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="section-title mb-35">
            <h2>Health & Well-Being</h2>
          </div>
          {/* <br></br> */}
          {Services.slice(2, 5).map((service, Sitem) => (
            <div className="col-lg-4 col-md-6 col-12 d-flex">
              <div className="service-item flex-fill" key={Sitem}>
                <span className="number">0{service.Id}</span>
                <img className="images" src={service.sImg} alt="Icon" />
                <h3>
                  <Link
                    onClick={ClickHandler}
                    to={`/service-single/${service.slug}`}
                  >
                    {service.sTitle}
                  </Link>
                </h3>
                <p>{truncateText(service.description, 60)}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="section-title mb-35">
            <h2>Character Building</h2>
          </div>
          {Services.slice(5, 8).map((service, Sitem) => (
            <div className="col-lg-4 col-md-6 col-12 d-flex">
              <div className="service-item flex-fill" key={Sitem}>
                <span className="number">0{service.Id}</span>
                <img className="images" src={service.sImg} alt="Icon" />
                <h3>
                  <Link
                    onClick={ClickHandler}
                    to={`/service-single/${service.slug}`}
                  >
                    {service.sTitle}
                  </Link>
                </h3>
                <p>{truncateText(service.description, 60)}</p>
              </div>
            </div>
          ))}
        </div>
        <div className="row">
          <div className="section-title mb-35">
            <h2>Community Development & Safety</h2>
          </div>
          {/* <br></br> */}
          {Services.slice(8, 11).map((service, Sitem) => (
            <div className="col-lg-4 col-md-6 col-12 d-flex">
              <div className="service-item flex-fill" key={Sitem}>
                <span className="number">0{service.Id}</span>
                <img className="images" src={service.sImg} alt="Icon" />
                <h3>
                  <Link
                    onClick={ClickHandler}
                    to={`/service-single/${service.slug}`}
                  >
                    {service.sTitle}
                  </Link>
                </h3>
                <p>{truncateText(service.description, 60)}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default ServiceSection2;
