import React, { useState } from "react";
import SimpleReactValidator from "simple-react-validator";
//import Bg from "../../images/contact/contact-section.png";
import axios from "axios";
import Swal from "sweetalert2";

const ContactSection2 = () => {
  const [forms, setForms] = useState({
    first_name: "",
    last_name: "",
    email: "",
    phone: "",
    message: "",
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };

  const submitHandler = async (e) => {
    e.preventDefault();

    if (validator.allValid()) {
      const payload = {
        first_name: forms.first_name,
        last_name: forms.last_name,
        email: forms.email,
        phone: forms.phone,
        // date: forms.date,
        // time: forms.time,
        message: forms.message,
      };

      try {
        const response = await axios.post(
          "https://api.commanderbuilders.com/api/contact-us",
          payload
        );

        // console.log('Form submitted successfully:', response.data);
        Swal.fire({
          icon: "success",
          title: "Form Submitted Successfully",
          text: "Thank you for your submission!",
        });
        setForms({
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          message: "",
        });

        validator.hideMessages();
      } catch (error) {
        // console.log(JSON.stringify(error.response));
        Swal.fire({
          icon: "error",
          title: "Submission Error",
          text:
            error.response.data.errors.phone ||
            error.response.data.errors.email ||
            error.response.data.errors.first_name ||
            error.response.data.errors.last_name ||
            error.response.data.errors.message,
        });
      }
    } else {
      validator.showMessages();
    }
    // if (validator.allValid()) {
    //     validator.hideMessages();
    //     setForms({
    //         name: '',
    //         email: '',
    //         // date: '',
    //         // time: '',
    //         message: ''
    //     })
    // } else {
    //     validator.showMessages();
    // }
  };

  return (
    <section
      // className="contact-section mb-250"
      // style={{ backgroundImage: `url(${Bg})` }}
      className="about-section-programs mb-150"
      style={{
        minHeight: "100vh",
        display: "flex",
        alignItems: "center",
        fontFamily: "'Roboto', sans-serif", // Font change
      }}
    >
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-xl-8 col-lg-10">
            <form
              className="contact-form bg-white mt-100 rmt-0"
              onSubmit={(e) => submitHandler(e)}
            >
              <div className="section-title text-center mb-40">
                <h2>Request a Visit</h2>
              </div>
              <div className="row clearfix">
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      className="form-control"
                      value={forms.first_name}
                      type="text"
                      name="first_name"
                      onBlur={(e) => changeHandler(e)}
                      onChange={(e) => changeHandler(e)}
                      placeholder="First Name"
                    />
                    {validator.message(
                      "first_name",
                      forms.first_name,
                      "required|alpha_space"
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      className="form-control"
                      value={forms.last_name}
                      type="text"
                      name="last_name"
                      onBlur={(e) => changeHandler(e)}
                      onChange={(e) => changeHandler(e)}
                      placeholder="Last Name"
                    />
                    {validator.message(
                      "last_name",
                      forms.last_name,
                      "required|alpha_space"
                    )}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      className="form-control"
                      value={forms.email}
                      type="email"
                      name="email"
                      onBlur={(e) => changeHandler(e)}
                      onChange={(e) => changeHandler(e)}
                      placeholder="Email"
                    />
                    {validator.message("email", forms.email, "required|email")}
                  </div>
                </div>

                <div className="col-md-12">
                  <div className="form-group">
                    <input
                      className="form-control"
                      value={forms.phone}
                      type="text"
                      name="phone"
                      onBlur={(e) => changeHandler(e)}
                      onChange={(e) => changeHandler(e)}
                      placeholder="Phone"
                    />
                    {validator.message("phone", forms.phone, "required|phone")}
                  </div>
                </div>
                <div className="col-md-12">
                  <div className="form-group">
                    <textarea
                      className="form-control"
                      rows="4"
                      onBlur={(e) => changeHandler(e)}
                      onChange={(e) => changeHandler(e)}
                      value={forms.message}
                      type="text"
                      name="message"
                      placeholder="Message"
                    ></textarea>
                    {validator.message("message", forms.message, "required")}
                  </div>
                </div>
                <div className="col-md-12">
                  <button type="submit" className="theme-btn mt-40">
                    Send
                  </button>
                </div>
              </div>
              <div className="title-rotated">contact</div>
            </form>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactSection2;
