import React, { useState } from "react";
import fImg from "../../images/faq/faq-right-bg.png";

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

const FaqSectionS2 = (props) => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <section className="faq-section2">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 pr-125 rpr-15">
            <div className="faq-left-image"></div>
          </div>
          <div className="col-lg-6 pl-0">
            <div className="faq-accordion ras-content pt-95 pb-110 rpb-0 rmb-100">
              <div className="section-title mb-35">
                {/* <h6>Empowering Communities, Shaping Futures:</h6> */}
                <h2>
                  Commander City's Initiatives
                  <span className="thin">For Growth and Prosperity</span>
                </h2>
                {/* <h2
                  style={{
                    textAlign: "right",
                    marginRight: "117px",
                    margin: 0,
                    fontSize: "18px",
                  }}
                >
                  Commander City
                </h2> */}
              </div>
              <div id="accordion">
                <Accordion open={open} toggle={toggle}>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="1" className="card-header">
                      Skill Development
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      Commander City offers free programs that provide hands-on
                      learning and real-world experience, particularly in the
                      construction sector, where participants gain practical
                      knowledge in project management, technical operations, and
                      site management. These programs also extend to other
                      industries, including manufacturing, production, and
                      services, giving individuals exposure to various career
                      paths. By making these opportunities accessible at no
                      cost, Commander City ensures that participants, regardless
                      of their financial background, can develop
                      industry-relevant skills, empowering them to pursue
                      meaningful careers or start their own ventures.
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="2" className="card-header">
                      Economic Empowerment
                    </AccordionHeader>
                    <AccordionBody accordionId="2">
                      Commander City drives economic empowerment through
                      initiatives that create earning opportunities and foster
                      entrepreneurship across various industries, including
                      construction, manufacturing, and retail. These initiatives
                      not only provide employment but also equip individuals
                      with the skills to start and grow their own businesses.
                      Additionally, the city ensures access to affordable
                      essentials, helping low-income families meet their daily
                      needs. By promoting local industries and encouraging
                      sustainable economic growth, these programs aim to reduce
                      financial disparities and support long-term economic
                      stability within the community.
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="3" className="card-header">
                      Health & Well-Being
                    </AccordionHeader>
                    <AccordionBody accordionId="3">
                      Commander City ensures the well-being of its residents
                      through free healthcare services, providing access to
                      essential medical care, preventive treatments, and
                      wellness support for all, regardless of financial
                      circumstances. In addition to healthcare, the city
                      addresses food security by offering free nutritious meals.
                      To promote healthy living, Commander City supplies organic
                      milk, vegetables, and meat, ensuring residents have access
                      to fresh and wholesome food. These initiatives aim to
                      build a healthier, more resilient community by removing
                      financial barriers to healthcare and nutrition, supporting
                      individuals in leading fulfilling and balanced lives.
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="4" className="card-header">
                      Character Building
                    </AccordionHeader>
                    <AccordionBody accordionId="4">
                      Commander City is committed to being a drug-free society,
                      fostering the personal growth and development of all
                      residents, especially children. The city offers services
                      aimed at grooming individuals from a young age, nurturing
                      their character, and equipping them with the skills to
                      become future leaders. A key focus is on holistic
                      development through education and sports. All students of
                      Commander Public School actively participate in outdoor
                      sports, ensuring they build discipline, teamwork, and
                      physical fitness. The most talented athletes are
                      identified and given advanced training to prepare them for
                      national and international competitions, including the
                      Olympics. These initiatives inspire individuals to reach
                      their full potential, promoting leadership, excellence,
                      and a healthy lifestyle for a brighter future.
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="5" className="card-header">
                      Community Development & Safety
                    </AccordionHeader>
                    <AccordionBody accordionId="5">
                      Commander City is committed to creating a safe, inclusive,
                      and thriving community through comprehensive social
                      welfare programs. As part of its mission to support
                      vulnerable populations, 10% of the houses in the city are
                      allocated for free living to deserving widows and orphans.
                      These widows are further empowered through earning
                      opportunities, helping them regain financial independence
                      and contribute to society. The city prioritizes safety and
                      security, employing a high-tech surveillance system and
                      well-equipped security guards to ensure a secure
                      environment for all residents. Commander City is also
                      designed to promote well-being, with beautiful gardens and
                      parks throughout the area, offering residents a peaceful,
                      family-friendly atmosphere. These initiatives reflect the
                      city’s dedication to fostering harmony, growth, and
                      prosperity for everyone.
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
            <div className="faq-right-bg">
              <img src={fImg} alt="FAQ" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSectionS2;
