import React, { Fragment } from "react";
import Navbar2 from "../../components/Navbar2/Navbar2";
//import PageTitle from "../../components/pagetitle/PageTitle";
import ServiceSection2 from "../../components/ServiceSection2/ServiceSection2";
import FaqSectionS2 from "../../components/FaqSectionS2/FaqSectionS2";
//import ContactSection from "../../components/ContactSection/ContactSection";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import { Helmet } from "react-helmet";
import PageTitlePrograms from "../../components/pagetitle/PageTitlePrograms";
import ContactSection2 from "../../components/ContactSection/ContactSection2";

const ServicePage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Programs & Services - Commander Builders</title>
        <meta
          name="description"
          content="Zindagi Badal Du (Youth Program) 
          Free Health Cards
          Commander City Industries (CCI Pure)
          Commander Welfare Mart
          Free Restaurant
          Building Futures - Creating Leaders
          Drug-Free Society
          Special Charity Program
          Commander Welfare Program"
        />
        <link rel="canonical" href="http://localhost:3036/our-programs" />
      </Helmet>
      <Navbar2 hclass={"header-style-2"} />
      <PageTitlePrograms
        pageTitle={"Programs & Services"}
        pagesub={"Programs & Services"}
      />
      <FaqSectionS2 />
      <ServiceSection2 />
      <ContactSection2 />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ServicePage;
