import simg from "../images/services/icon1.png";
import simg2 from "../images/services/icon2.png";
import simg3 from "../images/services/icon3.png";
import simg4 from "../images/services/icon4.png";
import simg5 from "../images/services/icon5.png";
import simg6 from "../images/services/icon6.png";
import simg7 from "../images/services/icon7.png";
import simg8 from "../images/services/icon8.png";
import simg9 from "../images/services/icon9.png";
import simg10 from "../images/services/icon10.png";
import simg11 from "../images/services/icon11.png";

import sSimg1 from "../images/service-single/zindagi/image1.JPG";
import sSimg2 from "../images/service-single/zindagi/image2.JPG";

import sSimg3 from "../images/service-single/industry/image1.png";
import sSimg4 from "../images/service-single/health/image2.png";
import sSimg5 from "../images/service-single/welfaremart/image1.JPG";

import sSimg6 from "../images/service-single/img-3.jpg";
// import sSimg4 from "../images/service-single/img-1.jpg";
//import sSimg5 from "../images/service-single/img-2.jpg";

import sSingleimg1 from "../images/service-single/2.jpg";
import sSingleimg2 from "../images/service-single/3.jpg";

//products
import product1 from "../images/service-single/industry/products/DataCable.png";

const Services = [
  {
    Id: "1",
    sImg: simg,
    // video: "/images/service-single/zindagi/youthprogram.mp4",
    sTitle: "Zindagi Badal Du Youth Program",
    slug: "Youth-Program",
    description:
      "Commander City's 'Zindagi Badal Du Youth Programme' is a transformative initiative aimed at empowering young adults (male/female) aged 18 to 35 by equipping them with essential skills for success in the business field.",
    programStructure: {
      img: sSimg1,
      theoreticalLearning: [
        "Participants engage in a structured curriculum that covers fundamental concepts and advanced topics in construction.",
        "Lectures and seminars are conducted by industry experts to provide a solid theoretical foundation.",
      ],
      handsOnWorkshops: [
        "Practical workshops complement theoretical learning, allowing participants to apply their knowledge in controlled environments.",
        "Workshops cover various aspects of construction, from basic techniques to advanced methods.",
      ],
      practicalFieldwork: [
        "Participants are involved in on-site training where they gain firsthand experience in the construction process.",
        "They observe and participate in the entire construction cycle, from groundbreaking to final finishing.",
      ],
    },
    mentorshipStructure: {
      img: sSimg2,
      mentorship: [
        "Each participant is paired with a professional mentor who guides them through the construction process step by step.",
        "Mentors provide personalized instruction and support, ensuring participants understand each phase of construction.",
      ],
      supervisedPracticalExperience: [
        "After initial training, participants are assigned to supervise units under construction.",
        "They are responsible for completing day-to-day tasks and ensuring the quality and progress of construction projects.",
      ],
    },
    performance: {
      // img: sSimg1,
      taskAssignmentandReporting: [
        "Participants are given specific tasks to manage and complete within the construction units they supervise.",
        "They are required to report their progress and any issues to their mentors regularly.",
      ],
      profitSharing: [
        "Participants receive a share of the profit from each unit they help construct, incentivizing high performance and accountability.",
      ],
      performanceAssessment: [
        "The performance of participants is continuously assessed based on their ability to manage construction tasks effectively and efficiently.",
        "Successful participants are assigned more units to supervise, allowing them to gain more experience and increase their earnings.",
      ],
    },
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "2",
    sImg: simg2,
    sSimage: sSimg3,
    sTitle: "Commander City Industries",
    slug: "Commander-City-Industries",
    description:
      "Commander City Industries (CCI) represents a significant stride towards industrialization, aiming to empower the common people economically.",
    paragraph1:
      "This initiative transcends traditional skill enhancement programs by offering tangible earning prospects for participants. By investing in human capital through comprehensive training and employment, CCI not only cultivates expertise but also fosters financial inclusion and a sense of ownership among its workforce. Employees not only receive competitive wages but also a stake in the profits generated from CCI's product lines, thereby incentivizing their active involvement in the production process",
    paragraph2:
      "In championing the production of organic goods, CCI not only contributes to economic empowerment but also advocates sustainability and environmental stewardship, further reinforcing its commitment to CSR principles. This holistic approach to industrialization reflects Commander City's commitment to leveraging human capital for prosperity, while also exemplifying responsible business practices and social accountability.",

    products: [
      {
        img: product1,
        name: "Data Cable",
      },
      {
        img: sSimg1,
        name: "Organic Product 2",
      },
      {
        img: sSimg1,
        name: "Organic Product 3",
      },
      {
        img: sSimg1,
        name: "Organic Product 4",
      },
      {
        img: sSimg1,
        name: "Organic Product 5",
      },
    ],
  },
  {
    Id: "3",
    sImg: simg3,
    sSimage: sSimg4,
    sTitle: "Free Health Cards",
    slug: "Free-Health-Cards",
    description:
      "Commander City's provision of free medical/health cards for all residents signifies a commitment to ensuring universal access to healthcare services. These cards grant residents access to medical care in over 200 hospitals across Pakistan, offering a comprehensive network of healthcare facilities.",
    paragraph1:
      "The initiative aims to address the healthcare needs of residents by removing financial barriers to medical services. By providing free access to healthcare, Commander City prioritizes the well-being and health of its residents, regardless of their financial status. ",
    paragraph2:
      "This initiative underscores Commander City's commitment to promoting the health and welfare of its residents as a cornerstone of its community development efforts.",
  },
  {
    Id: "4",
    sImg: simg4,
    sTitle: "Commander Welfare Mart",
    slug: "Commander-Welfare-Mart",
    description:
      "Commander Welfare Mart extends a valuable service to the community of Commander City by offering grocery items at subsidized rates. This initiative aims to alleviate financial burdens for residents while ensuring access to essential commodities. In addition to offering essential commodities, the mart distinguishes itself by sourcing organic meat from Commander Farms, as well as organic fruits and vegetables. This initiative not only promotes healthy living but also underscores Commander City's commitment to sustainable and environmentally-friendly practices.",
    sSimage: sSimg5,
    paragraph1:
      "This commitment to affordability underscores Commander City's dedication to supporting the well-being and prosperity of its residents and promotes a culture of health-consciousness within the community.",
    ssImg1: sSingleimg1,
    ssImg2: sSingleimg2,
  },
  {
    Id: "5",
    sImg: simg5,
    sSimg: sSimg5,
    sTitle: "Free Restaurant",
    slug: "Free-Restaurant",
    description:
      "Commander City boasts a remarkable amenity—a free restaurant that provides lunch and dinner at no cost to all residents and the working community within its premises. This inclusive initiative extends to anyone walking into the restaurant, where they can enjoy complimentary meals. ",
    paragraph1:
      "With a separate family hall, the restaurant ensures a comfortable and welcoming environment for all patrons. Not only does the restaurant prioritize affordability, but it also maintains high standards of food quality and hygiene.",
    paragraph2:
      "This commitment to providing nutritious meals in a clean and hygienic setting reflects Commander City's dedication to fostering community well-being and stands as a shining example of how businesses can integrate CSR into their operations to make a positive impact on society.",
  },
  {
    Id: "6",
    sImg: simg6,
    sSimg: sSimg6,
    sTitle: "Drug Free Society",
    slug: "Drug-Free-Society",
    description:
      "Commander City is deeply committed to creating a drug-free environment that promotes character building, personal growth, and the development of future leaders. This initiative is not limited to simply preventing substance abuse; it embodies a holistic approach aimed at fostering a brighter future for residents by nurturing values, discipline, and leadership skills from a young age. To ensure the community remains safe and secure, Commander City has implemented an advanced, state-of-the-art surveillance system. High-resolution cameras are strategically placed across key areas, continuously monitoring public spaces to prevent illicit activities and ensure the well-being of all residents. This surveillance system is supported by a dedicated security cell that operates around the clock, proactively addressing potential threats and maintaining a sense of safety throughout the city.",
    paragraph1:
      "The presence of highly trained guards further enhances security, with regular patrols and quick response teams in place to address any incidents promptly. These measures not only deter crime and substance abuse but also contribute to the peace of mind of residents, reinforcing their trust in the community's governance.",
    paragraph2:
      "Beyond physical security, Commander City actively promotes well-being through programs focused on youth development, education, and personal growth. Schools and recreational activities are designed to engage children and teenagers constructively, channeling their energy into sports, arts, and leadership training. This proactive approach ensures that residents, especially the youth, are empowered to build strong futures, away from the influence of drugs or harmful activities. By prioritizing security, personal development, and collective well-being, Commander City fosters a sense of trust and belonging within the community. The goal is not just to safeguard its inhabitants but also to create an environment where every individual feels valued and motivated to thrive. This nurturing atmosphere lays the foundation for a harmonious and flourishing society, driven by empowered individuals working toward a shared vision of progress.",
  },
  {
    Id: "7",
    sImg: simg7,
    sSimg: sSimg6,
    sTitle: "Commander Public School",
    slug: "Commander-City-Public-School",
    description:
      "Commander Public School offers an exceptional education system that combines academic excellence with holistic development. The school is committed to providing a well-rounded learning experience, ensuring students excel not only in academics but also in personal growth and character building. With a curriculum aligned with the latest educational standards, students receive high-quality instruction from experienced teachers who nurture critical thinking, creativity, and leadership skills.",
    paragraph1:
      "In addition to academic learning, Commander Public School places significant emphasis on extracurricular activities, recognizing the importance of physical and mental well-being. Students are encouraged to participate in a variety of activities such as swimming, horse riding, and archery, fostering discipline, teamwork, and focus. These activities help cultivate confidence, physical fitness, and resilience, preparing students to face challenges both inside and outside the classroom.",
    paragraph2:
      "The school also provides state-of-the-art sports facilities and ensures that every child has the opportunity to explore their talents. Regular competitions and events are organized to engage students, and the most promising athletes are given advanced training to compete at national and international levels, including the Olympics. Through this balanced approach to education and extracurricular development, Commander Public School aims to nurture future leaders who are not only academically accomplished but also physically active, emotionally strong, and socially responsible.",
  },
  {
    Id: "8",
    sImg: simg8,
    sSimg: sSimg6,
    sTitle: "Seminars & Workshops",
    slug: "Seminars-And-Workshops",
    description:
      "Commander City is committed to the personal and professional development of its residents by offering free seminars, workshops, and mentorship programs. These initiatives focus on character building, skill enhancement, and career growth, equipping individuals with the tools needed to thrive in today’s dynamic world. Residents have access to expert-led sessions that cover topics like leadership, emotional intelligence, communication, and entrepreneurship, fostering both personal and professional growth.",
    paragraph1:
      "In addition to seminars, the city offers hands-on workshops in diverse fields, ranging from technical skills to creative arts, ensuring participants gain practical experience relevant to their career paths. Dedicated mentorship programs connect residents with professionals and industry leaders, providing valuable guidance, networking opportunities, and real-world insights. These mentors help individuals navigate their personal challenges, make informed career decisions, and develop actionable plans to achieve their goals.",
    paragraph2:
      "The focus on continuous learning ensures that residents, regardless of age or background, have the opportunity to improve and grow. Specialized career workshops offer training in resume building, job search strategies, interview preparation, and professional development, empowering participants to succeed in the workforce. Through these efforts, Commander City cultivates an environment of lifelong learning, ensuring that every individual has access to the resources, support, and opportunities needed to unlock their potential and contribute meaningfully to the community.",
  },
  {
    Id: "9",
    sImg: simg9,
    sSimg: sSimg6,
    sTitle: "Special Charity Program",
    slug: "Special-Charity-Program",
    description:
      "Special Charity Program for widows, orphans & disabled 10% houses",
    paragraph1:
      "Commander City extends a compassionate hand to the vulnerable in society, reserving 5% of its houses free of cost for widows, orphans, and the disabled. Through a meticulous selection process, the most deserving individuals receive an 80 square yards bungalow.",
    paragraph2:
      "Furthering their support, the Commander Welfare Program offers additional assistance. This includes providing earning opportunities within the community, free education and skill development programs for their children, and access to complimentary healthcare facilities. To alleviate their financial burden, recipients are provided with Rashan Cards for free grocery items and are exempted from all utility charges. They are graciously allowed to reside in their houses rent-free until their children reach an age where they can support their families. In extending these provisions, Commander City strives to create a nurturing environment where every resident can thrive, regardless of their circumstances.",
  },
  {
    Id: "10",
    sImg: simg10,
    sSimg: sSimg6,
    sTitle: "Safety & Security",
    slug: "Safety-And-Security",
    description:
      "Commander City prioritizes the safety and well-being of its residents by providing a secure, peaceful environment through advanced safety measures. The entire community is monitored 24/7 with a high-tech surveillance system featuring state-of-the-art cameras strategically placed across public spaces. A dedicated security cell operates round-the-clock, proactively addressing potential threats and ensuring a swift response to emergencies.",
    paragraph1:
      "Highly trained security personnel, including well-equipped guards, conduct regular patrols to maintain order and safety. Access points throughout the city are carefully managed to prevent unauthorized entry, ensuring residents feel secure in their homes and public spaces. Beyond physical security, Commander City fosters a culture of trust and well-being by actively promoting community awareness and engagement. Programs and initiatives focused on crime prevention, substance abuse awareness, and responsible citizenship empower residents to play an active role in maintaining the community’s safety.",
    paragraph2:
      "With parks, gardens, and recreational spaces designed for families, the city offers not only a secure but also a serene living experience. Commander City’s commitment to safety ensures that residents can thrive in an environment that promotes both physical security and peace of mind, creating a harmonious community where everyone feels protected and valued.",
  },
  {
    Id: "11",
    sImg: simg11,
    sSimg: sSimg5,
    sTitle: "Sustainable Development",
    slug: "Sustainable-Development",
    description:
      "Commander City's vision for the future includes providing clean & renewable energy, as part of its commitment to sustainable development.",
    paragraph1:
      "Currently, the city offers 100 units of electricity free to its residents, ensuring uninterrupted power supply 24/7 without any disruptions. By transitioning to renewable energy sources such as solar power and implementing innovative ideas and execution, the city aims to make these essential utilities accessible to all in the best way possible. To accomplish this objective, Commander City plans to inaugurate a solar energy workshop to fully utilize solar technology's capabilities. Embracing renewable energy solutions not only diminishes the carbon footprint but also supports the United Nations' Sustainable Development Goals, specifically in affordable and clean energy (SDG 7) and sustainable cities and communities (SDG 11).",
    paragraph2:
      "In addition, the city holds a steadfast commitment to ensuring that clean energy is accessible to the underprivileged at no cost. Recognizing the critical importance of equitable access to sustainable resources, the city's vision extends to implementing initiatives specifically tailored to uplift underserved communities. By prioritizing the needs of the underprivileged, the city aims to bridge the gap in energy access and promote inclusive growth and development.",
  },
];

export default Services;
