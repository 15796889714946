import React, { useState } from "react";
import SimpleReactValidator from "simple-react-validator";
import axios from "axios";
import Swal from "sweetalert2";

const CtaSection = () => {
  const [forms, setForms] = useState({
    email: "",
  });
  const [validator] = useState(
    new SimpleReactValidator({
      className: "errorMessage",
    })
  );
  const changeHandler = (e) => {
    setForms({ ...forms, [e.target.name]: e.target.value });
    if (validator.allValid()) {
      validator.hideMessages();
    } else {
      validator.showMessages();
    }
  };
  const submitHandler = async (e) => {
    e.preventDefault();
    if (validator.allValid()) {
      const payload = {
        email: forms.email,
      };

      try {
        const response = await axios.post(
          "https://api.commanderbuilders.com/api/news-letter",
          payload
        );

        console.log("Form submitted successfully:", response.data);
        Swal.fire({
          icon: "success",
          title: "Form Submitted Successfully",
          text: "Thank you for your subscribing!",
        });
        setForms({
          first_name: "",
          last_name: "",
          email: "",
          phone: "",
          message: "",
        });

        validator.hideMessages();
      } catch (error) {
        // console.log(JSON.stringify(error.response));
        Swal.fire({
          icon: "error",
          title: "Submission Error",
          text: error.response.data.errors.email,
        });
      }
    } else {
      validator.showMessages();
    }
  };

  return (
    <section className="call-action">
      <div className="container">
        <div className="call-action-inner">
          <div className="row align-items-center">
            <div className="col-lg-6">
              <div className="section-title text-white rmb-35">
                <h6>get quote</h6>
                <h2>call for action</h2>
              </div>
            </div>
            <div className="col-lg-6">
              <form onSubmit={(e) => submitHandler(e)} className="subscribe">
                <input
                  className="form-control"
                  value={forms.email}
                  type="email"
                  name="email"
                  onBlur={(e) => changeHandler(e)}
                  onChange={(e) => changeHandler(e)}
                  placeholder="Enter Email"
                />
                {validator.message("email", forms.email, "required|email")}

                <button type="submit" className="theme-btn style-two">
                  subscribe
                </button>
              </form>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CtaSection;
