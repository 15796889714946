import React, { useState } from "react";
import fImg from "../../images/faq/faq-right-bg.png";

import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
} from "reactstrap";

const FaqSection = (props) => {
  const [open, setOpen] = useState("1");
  const toggle = (id) => {
    if (open === id) {
      setOpen();
    } else {
      setOpen(id);
    }
  };

  return (
    <section className="faq-section">
      <div className="container-fluid">
        <div className="row">
          <div className="col-lg-6 pr-125 rpr-15">
            <div className="faq-left-image"></div>
          </div>
          <div className="col-lg-6 pl-0">
            <div className="faq-accordion ras-content pt-95 pb-110 rpb-0 rmb-100">
              <div className="section-title mb-35">
                <h6>The Largest</h6>
                <h2>
                  Welfare Housing Society
                  <span className="thin">
                    Is In The Making:
                    <span
                      className="city-name"
                      style={{
                        textAlign: "right",
                        marginRight: "117px",
                        margin: 0,
                        fontSize: "25px",
                        paddingLeft: "120px",
                        fontWeight: "bold",
                      }}
                    >
                      Commander City
                    </span>
                  </span>
                </h2>
              </div>
              <div id="accordion">
                <Accordion open={open} toggle={toggle}>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="1" className="card-header">
                      The Purpose
                    </AccordionHeader>
                    <AccordionBody accordionId="1">
                      Commander City is a visionary welfare housing project
                      striving to become the world’s largest community by 2040.
                      With 1 million housing units in planning, 500 educational
                      and skill development institutes, and 100,000 free homes
                      for widows and orphans, it offers a secure living
                      environment, business opportunities, and comprehensive
                      social care, fostering a peaceful and prosperous future
                      for all.
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="2" className="card-header">
                      The Mastermind
                    </AccordionHeader>
                    <AccordionBody accordionId="2">
                      Commander (R) Muhammad Zakir, founder and Chairman of
                      Commander Builders (SMC-PVT) Ltd., is the visionary
                      mastermind behind Commander City. With 28 years of
                      honourable service in the Pakistan Navy, he embarked on a
                      mission to provide affordable, high-quality living for
                      middle and lower-income families. His visionary leadership
                      extends beyond housing, with welfare initiatives such as
                      free homes for widows and orphans, free skill development
                      programs for the youth, numerous earning opportunities and
                      much more. Guided by Quranic teachings, his Vision 2040
                      aims to build sustainable communities across Pakistan,
                      redefining affordable living and setting new standards in
                      social impact and development.
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="3" className="card-header">
                      The Progress
                    </AccordionHeader>
                    <AccordionBody accordionId="3">
                      Commander City, situated along the Karachi-Hyderabad M9
                      motorway, has rapidly developed into a thriving
                      residential community. With over 4,000 members and 1,000
                      developed units, it is home to more than 600 families. The
                      community's operational school and skill development
                      institute serve 2,000 students, showcasing a strong
                      commitment to education. State-of-the-art recreational
                      facilities, including sports amenities, lush parks, and a
                      central garden with a lake view and vibrant food street,
                      greatly enhance residents' quality of life. Comprehensive
                      commercial and healthcare services, along with organic
                      food supply and free water, ensure convenience and promote
                      healthy living. Commander City is a model welfare society,
                      combining modern amenities with a focus on community
                      well-being, making it one of the premier places to live in
                      Pakistan.
                    </AccordionBody>
                  </AccordionItem>
                  <AccordionItem className="card">
                    <AccordionHeader targetId="4" className="card-header">
                      The Process
                    </AccordionHeader>
                    <AccordionBody accordionId="4">
                      Certainly! Commander City epitomizes a vision of
                      compassion, empowerment, and community enrichment.
                      Grounded in the principles of faith, willpower, and
                      passion, this society is driven by a profound belief in
                      the transformative power of giving. At its core, Commander
                      City is a beacon of hope, offering a haven for all its
                      residents regardless of their circumstances. Looking ahead
                      to its ambitious Vision 2040, Commander City aspires to
                      become a model of holistic development and prosperity.
                      With a focus on nurturing human capital, fostering
                      economic empowerment, and promoting sustainable practices,
                      the city aims to realize its vision through a multifaceted
                      approach.
                    </AccordionBody>
                  </AccordionItem>
                </Accordion>
              </div>
            </div>
            <div className="faq-right-bg">
              <img src={fImg} alt="FAQ" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default FaqSection;
