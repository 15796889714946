import React, { useRef } from "react";
import { Navigation, A11y, Autoplay, EffectFade } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/effect-fade";
import { Link } from "react-router-dom";
import hero1 from "../../images/slider/video1.mp4";
import hero2 from "../../images/slider/video2.mp4";
import hero3 from "../../images/slider/video3.mp4";
import hero4 from "../../images/slider/video4.mp4";
import hero5 from "../../images/slider/video5.mp4";
import "./style.scss";

const Hero2 = () => {
  const swiperRef = useRef(null);

  const resetVideo = (index) => {
    const videos = document.querySelectorAll(".slide-video");
    videos.forEach((video, i) => {
      if (i === index) {
        video.currentTime = 0; // Reset to start
        video.play(); // Play the video
      } else {
        video.pause(); // Pause all other videos
      }
    });
  };

  return (
    <section className="hero-slider hero-style-4">
      <Swiper
        modules={[Autoplay, Navigation, A11y, EffectFade]}
        effect="fade" // Use fade effect for instant slide changes
        fadeEffect={{ crossFade: true }} // Crossfade between slides
        spaceBetween={0}
        slidesPerView={1}
        loop={true}
        speed={1} // Almost instant transition speed
        navigation
        autoplay={{ delay: 4500, disableOnInteraction: false }}
        onSwiper={(swiper) => (swiperRef.current = swiper)}
        onSlideChange={(swiper) => resetVideo(swiper.activeIndex)}
      >
        <SwiperSlide>
          <div className="slide-inner">
            <video className="slide-video" autoPlay muted loop playsInline>
              <source src={hero1} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="container-fluid">
              <div className="slide-content text-left">
                <div data-swiper-parallax="300" className="slide-title">
                  <h2 style={{ color: "#ffffff" }}>Our Mission</h2>
                </div>
                <div data-swiper-parallax="400" className="slide-text">
                  <p>
                    Building communities that empower lives through sustainable
                    living and economic growth.
                  </p>
                </div>
                <div className="slide-btns" data-swiper-parallax="500">
                  <Link to="/about" className="theme-btn">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="slide-inner">
            <video className="slide-video" autoPlay muted loop playsInline>
              <source src={hero2} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="container-fluid">
              <div className="slide-content text-left">
                <div data-swiper-parallax="300" className="slide-title">
                  <h2 style={{ color: "#ffffff" }}>Vision 2040</h2>
                </div>
                <div data-swiper-parallax="400" className="slide-text">
                  <p>
                    Transforming Pakistan into a global economic leader through
                    welfare cities that foster growth, education, healthcare,
                    and innovation.
                  </p>
                </div>
                <div className="slide-btns" data-swiper-parallax="500">
                  <Link to="/blog-single/our-vision" className="theme-btn">
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>

        <SwiperSlide>
          <div className="slide-inner">
            <video className="slide-video" autoPlay muted loop playsInline>
              <source src={hero3} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="container-fluid">
              <div className="slide-content text-left">
                <div data-swiper-parallax="300" className="slide-title">
                  <h2 style={{ color: "#ffffff" }}>Milestone Achieved</h2>
                </div>
                <div data-swiper-parallax="400" className="slide-text">
                  <p>
                    From a dream to reality, empowering 10,000+ families with
                    education and economic growth, earning global acclaim for
                    its visionary founder, Commander (R) Muhammad Zakir.
                  </p>
                </div>
                <div className="slide-btns" data-swiper-parallax="500">
                  <Link
                    to="/blog-single/milestone-achieved"
                    className="theme-btn"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide-inner">
            <video className="slide-video" autoPlay muted loop playsInline>
              <source src={hero4} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="container-fluid">
              <div className="slide-content text-left">
                <div data-swiper-parallax="300" className="slide-title">
                  <h2 style={{ color: "#ffffff" }}>Youth Program</h2>
                </div>
                <div data-swiper-parallax="400" className="slide-text">
                  <p>
                    Empowering young adults with hands-on skills, mentorship,
                    and real-world experience to thrive in construction or
                    launch their entrepreneurial journeys.
                  </p>
                </div>
                <div className="slide-btns" data-swiper-parallax="500">
                  <Link
                    to="/service-single/Youth-Program"
                    className="theme-btn"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
        <SwiperSlide>
          <div className="slide-inner">
            <video className="slide-video" autoPlay muted loop playsInline>
              <source src={hero5} type="video/mp4" />
              Your browser does not support the video tag.
            </video>
            <div className="container-fluid">
              <div className="slide-content text-left">
                <div data-swiper-parallax="300" className="slide-title">
                  <h2 style={{ color: "#ffffff" }}>Commander City</h2>
                </div>
                <div data-swiper-parallax="400" className="slide-text">
                  <p>
                    A dynamic welfare community transforming lives through
                    education, healthcare, skill development, and economic
                    empowerment, paving the way for a prosperous Pakistan.
                  </p>
                </div>
                <div className="slide-btns" data-swiper-parallax="500">
                  <Link
                    to="/project-single/Commander-City"
                    className="theme-btn"
                  >
                    Read More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </SwiperSlide>
      </Swiper>
    </section>
  );
};

export default Hero2;
