import React, { Fragment } from "react";
import Navbar2 from "../../components/Navbar2/Navbar2";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useParams } from "react-router-dom";
import Services from "../../api/Services";
import Footer from "../../components/footer/Footer";
import "./style.scss";
//import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import ZindagiBadaldo from "../../components/pagetitle/ZindagiBadaldo";

// Import images directly
import YouthProgramImage from "../../images/service-single/zindagi/image3.jpeg";
import CommanderCityIndustriesImage from "../../images/service-single/industry/image2.png";
import FreeHealthCardsImage from "../../images/service-single/health/image1.jpg";
import WelfareMartImage from "../../images/service-single/welfaremart/image1.jpeg";
import FreeRestaurantImage from "../../images/service-single/restaurant/dsdsd.jpg";
import DrugFreeSocietyImage from "../../images/service-single/drugfree/image1.jpg";
import PublicSchoolImage from "../../images/service-single/school/image1.jpg";
import SeminarsImage from "../../images/service-single/saminars/SHZ_8749.JPG";
import CharityProgramImage from "../../images/service-single/saminars/SHZ_8326.JPG";
import SafetySecurityImage from "../../images/service-single/safetyandsecurity/image2.jpeg";
import SustainableDevelopmentImage from "../../images/service-single/industry/339739724_192487066912680_1507257856538173696_n.jpg";
import DefaultImage from "../../images/service-single/zindagi/image1.JPG";

const ServiceSinglePage = () => {
  const { slug } = useParams();
  const serviceDetails = Services.find((item) => item.slug === slug);

  if (!serviceDetails) {
    return <div>Service not found</div>;
  }

  // Define page title, subtitle, and background image based on the slug
  let pageTitle = serviceDetails.sTitle;
  let pagesub = "Our Programs";
  let backgroundImage = DefaultImage; // Default background image
  let bannerClass = "banner-section-default"; // Default class

  switch (slug) {
    case "Youth-Program":
      pageTitle = "Zindagi Badaldo Youth Program";
      backgroundImage = YouthProgramImage;
      bannerClass = "banner-section-youth-program";
      break;
    case "Commander-City-Industries":
      pageTitle = "Commander City Industries";
      backgroundImage = CommanderCityIndustriesImage;
      bannerClass = "banner-section-industries";
      break;
    case "Free-Health-Cards":
      pageTitle = "Free Health Cards";
      backgroundImage = FreeHealthCardsImage;
      bannerClass = "banner-section-free-health-card";
      break;
    case "Commander-Welfare-Mart":
      pageTitle = "Commander Welfare Mart";
      backgroundImage = WelfareMartImage;
      bannerClass = "banner-section-welfaremart";
      break;
    case "Free-Restaurant":
      pageTitle = "Free Restaurant";
      backgroundImage = FreeRestaurantImage;
      bannerClass = "banner-section-restaurant";
      break;
    case "Drug-Free-Society":
      pageTitle = "Drug Free Society";
      backgroundImage = DrugFreeSocietyImage;
      bannerClass = "banner-section-drugfree";
      break;
    case "Commander-City-Public-School":
      pageTitle = "Commander Public School";
      backgroundImage = PublicSchoolImage;
      bannerClass = "banner-section-school";
      break;
    case "Seminars-And-Workshops":
      pageTitle = "Seminars and Workshops";
      backgroundImage = SeminarsImage;
      bannerClass = "banner-section-seminars";
      break;
    case "Special-Charity-Program":
      pageTitle = "Special Charity Program";
      backgroundImage = CharityProgramImage;
      bannerClass = "banner-section-charityprogram";
      break;
    case "Safety-And-Security":
      pageTitle = "Safety and Security";
      backgroundImage = SafetySecurityImage;
      bannerClass = "banner-section-safetyandsecurity";
      break;
    case "Sustainable-Development":
      pageTitle = "Sustainable Development";
      backgroundImage = SustainableDevelopmentImage;
      bannerClass = "banner-section-sustainable";
      break;
    default:
      pageTitle = serviceDetails.sTitle;
      backgroundImage = DefaultImage;
  }

  // const sliderSettings = {
  //   dots: true,
  //   infinite: true,
  //   speed: 600,
  //   slidesToShow: 1,
  //   slidesToScroll: 1,
  //   autoplay: true,
  //   autoplaySpeed: 4000,
  // };

  return (
    <Fragment>
      <Navbar2 hclass={"header-style-2"} />
      <ZindagiBadaldo
        pageTitle={pageTitle}
        pagesub={pagesub}
        img={backgroundImage}
        bannerClass={bannerClass}
      />
      <div className="service-single-area section-padding">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-10 col-12">
              <div className="service-single-wrap">
                <div className="service-single-item">
                  <div className="service-single-title">
                    <h3>{serviceDetails.sTitle}</h3>
                  </div>
                  <p>{serviceDetails.description}</p>
                </div>

                {/* Conditional rendering based on slug */}
                {slug === "Youth-Program" && (
                  <Fragment>
                    {/* <div className="service-single-main-video">
                      <video width="100%" controls>
                        <source src={serviceDetails.video} type="video/mp4" />
                        Your browser does not support the video tag.
                      </video>
                    </div> */}
                    <br></br>
                    {/* Program Structure and Curriculum */}
                    <p>
                      This 6-month training program encompasses a comprehensive
                      curriculum consisting of theoretical learning, hands-on
                      workshops, and practical fieldwork experiences.
                      Participants are immersed in real-world scenarios within
                      the construction business, gaining valuable insights and
                      professional experiences. Through practical tasks and
                      mentorship from industry experts, they develop a diverse
                      skill set tailored to the demands of the construction
                      industry or their desired field. By the end of the
                      program, participants emerge with the confidence and
                      competence to excel on construction sites or embark on
                      their entrepreneurial journeys. The "Zindagi Badal Du
                      Youth Programme" not only fosters personal growth and
                      development but also serves as a catalyst for economic
                      empowerment and sustainable livelihoods within Commander
                      City and beyond.
                    </p>
                    <div className="service-single-item">
                      <div className="service-single-title">
                        <h2>Program Structure and Curriculum</h2>
                      </div>

                      <div className="service-single-content">
                        <h4>1. Theoretical Learning:</h4>
                        <ul>
                          {serviceDetails.programStructure.theoreticalLearning.map(
                            (item, idx) => (
                              <li key={idx}>{item}</li>
                            )
                          )}
                        </ul>
                        <h4>2. Hands-on Workshops:</h4>
                        <ul>
                          {serviceDetails.programStructure.handsOnWorkshops.map(
                            (item, idx) => (
                              <li key={idx}>{item}</li>
                            )
                          )}
                        </ul>
                        <h4>3. Practical Fieldwork:</h4>
                        <ul>
                          {serviceDetails.programStructure.practicalFieldwork.map(
                            (item, idx) => (
                              <li key={idx}>{item}</li>
                            )
                          )}
                        </ul>
                      </div>
                      <div className="service-single-img">
                        <img src={serviceDetails.programStructure.img} alt="" />
                      </div>
                      {/* <div className="service-single-img">
                        <img
                          src={serviceDetails.programStructure.img1}
                          alt=""
                        />
                      </div> */}
                    </div>

                    {/* Mentorship and Supervision */}
                    <div className="service-single-item">
                      <div className="service-single-title">
                        <h2>Mentorship and Supervision</h2>
                      </div>

                      <div className="service-single-content">
                        <h4>1. Mentorship:</h4>
                        <ul>
                          {serviceDetails.mentorshipStructure.mentorship.map(
                            (item, idx) => (
                              <li key={idx}>{item}</li>
                            )
                          )}
                        </ul>
                        <h4>2. Supervised Practical Experience:</h4>
                        <ul>
                          {serviceDetails.mentorshipStructure.supervisedPracticalExperience.map(
                            (item, idx) => (
                              <li key={idx}>{item}</li>
                            )
                          )}
                        </ul>
                      </div>
                      <div className="service-single-img">
                        <img
                          src={serviceDetails.mentorshipStructure.img}
                          alt=""
                        />
                      </div>
                    </div>

                    {/* Performance Assessment and Rewards */}
                    <div className="service-single-item">
                      <div className="service-single-title">
                        <h2>Performance Assessment and Rewards</h2>
                      </div>

                      <div className="service-single-content">
                        <h4>1. Task Assignment and Reporting:</h4>
                        <ul>
                          {serviceDetails.performance.taskAssignmentandReporting.map(
                            (item, idx) => (
                              <li key={idx}>{item}</li>
                            )
                          )}
                        </ul>
                        <h4>2. Profit Sharing:</h4>
                        <ul>
                          {serviceDetails.performance.profitSharing.map(
                            (item, idx) => (
                              <li key={idx}>{item}</li>
                            )
                          )}
                        </ul>
                        <h4>3. Supervised Practical Experience:</h4>
                        <ul>
                          {serviceDetails.performance.performanceAssessment.map(
                            (item, idx) => (
                              <li key={idx}>{item}</li>
                            )
                          )}
                        </ul>
                      </div>
                      <div className="service-single-img">
                        <img src={serviceDetails.performance.img} alt="" />
                      </div>
                    </div>
                  </Fragment>
                )}

                {slug === "Commander-City-Industries" && (
                  <Fragment>
                    <div className="service-single-main-img">
                      <img src={serviceDetails.sSimage} alt="" />
                    </div>
                    <br></br>
                    <p>{serviceDetails.paragraph1}</p>
                    <p>{serviceDetails.paragraph2}</p>
                    {/* <div className="service-single-title">
                      <h2>Our Products</h2>
                    </div>
                    <Slider {...sliderSettings}>
                      {serviceDetails.products.map((product, idx) => (
                        <div key={idx}>
                          <img src={product.img} alt={product.name} />
                          <h4 className="productname">{product.name}</h4>
                        </div>
                      ))}
                    </Slider> */}
                  </Fragment>
                )}

                {slug === "Free-Health-Cards" && (
                  <Fragment>
                    <div className="service-single-main-img">
                      <img src={serviceDetails.sSimage} alt="" />
                    </div>
                    <br></br>
                    <p>{serviceDetails.paragraph1}</p>
                    <p>{serviceDetails.paragraph2}</p>
                  </Fragment>
                )}

                {slug === "Commander-Welfare-Mart" && (
                  <Fragment>
                    <div className="service-single-main-img">
                      <img src={serviceDetails.sSimage} alt="" />
                    </div>
                    <br></br>
                    <div className="service-single-item">
                      <p>{serviceDetails.paragraph1}</p>
                    </div>
                  </Fragment>
                )}

                {slug === "Free-Restaurant" && (
                  <Fragment>
                    <div className="service-single-item">
                      <p>{serviceDetails.paragraph1}</p>
                      <p>{serviceDetails.paragraph2}</p>
                    </div>
                  </Fragment>
                )}

                {slug === "Drug-Free-Society" && (
                  <Fragment>
                    <div className="service-single-item">
                      <p>{serviceDetails.paragraph1}</p>
                      <p>{serviceDetails.paragraph2}</p>
                    </div>
                  </Fragment>
                )}

                {slug === "Commander-City-Public-School" && (
                  <Fragment>
                    <div className="service-single-item">
                      <p>{serviceDetails.paragraph1}</p>
                      <p>{serviceDetails.paragraph2}</p>
                    </div>
                  </Fragment>
                )}

                {slug === "Seminars-And-Workshops" && (
                  <Fragment>
                    <div className="service-single-item">
                      <p>{serviceDetails.paragraph1}</p>
                      <p>{serviceDetails.paragraph2}</p>
                    </div>
                  </Fragment>
                )}

                {slug === "Special-Charity-Program" && (
                  <Fragment>
                    <div className="service-single-item">
                      <p>{serviceDetails.paragraph1}</p>
                      <p>{serviceDetails.paragraph2}</p>
                    </div>
                  </Fragment>
                )}

                {slug === "Safety-And-Security" && (
                  <Fragment>
                    <div className="service-single-item">
                      <p>{serviceDetails.paragraph1}</p>
                      <p>{serviceDetails.paragraph2}</p>
                    </div>
                  </Fragment>
                )}

                {slug === "Sustainable-Development" && (
                  <Fragment>
                    <div className="service-single-item">
                      <p>{serviceDetails.paragraph1}</p>
                      <p>{serviceDetails.paragraph2}</p>
                    </div>
                  </Fragment>
                )}

                {/* Default content for other slugs */}
                {![
                  "Youth-Program",
                  "Commander-City-Industries",
                  "Free-Health-Cards",
                  "Commander-Welfare-Mart",
                  "Free-Restaurant",
                  "Drug-Free-Society",
                  "Commander-City-Public-School",
                  "Seminars-And-Workshops",
                  "Special-Charity-Program",
                  "Safety-And-Security",
                  "Sustainable-Development",
                ].includes(slug) && (
                  <Fragment>
                    <p>{serviceDetails.paragraph1}</p>
                    <p>{serviceDetails.paragraph2}</p>
                  </Fragment>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default ServiceSinglePage;
