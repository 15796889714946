import pImg1 from "../images/apartment/apartment2.jpg";
import pImg2 from "../images/apartment/apartment1.jpg";
//import pImg3 from "../images/apartment/apartment4.png";
import pImg4 from "../images/apartment/apartment4.jpg";

import pSImg1 from "../images/apartment/apartment1.png";
import pSImg2 from "../images/apartment/apartment2.png";
import pSImg3 from "../images/apartment/apartment3.jpg";
import pSImg4 from "../images/apartment/apartment4.jpg";

//import sIcon1 from "../images/apartment/icon1.png";
import sIcon2 from "../images/apartment/icon2.png";
//import sIcon3 from "../images/apartment/icon3.png";
import sIcon4 from "../images/apartment/icon4.png";
import sIcon5 from "../images/apartment/icon5.png";
import sIcon6 from "../images/apartment/icon6.png";

const Projects = [
  {
    Id: "1",
    pTitle: "Commander City",
    slug: "Commander-City",
    pImg: pSImg3,
    pSImg: pSImg3,
    ssIcon: sIcon2,
    des: "Description specific to Commander City",
    width: "130",
  },
  {
    Id: "2",
    pTitle: "Commander Enclave",
    slug: "Commander-Enclave",
    pImg: pImg1,
    pSImg: pSImg1,
    ssIcon: sIcon4,
    des: "Aenean vehicula non mauris",
    width: "120",
  },
  {
    Id: "3",
    pTitle: "Commander Heights",
    slug: "Commander-Heights",
    pImg: pImg2,
    pSImg: pSImg2,
    ssIcon: sIcon5,
    des: "Aenean vehicula non mauris.",
    width: "130",
  },
  {
    Id: "4",
    pTitle: "Breezy Commander Heights",
    slug: "Breezy-Commander-Heights",
    pImg: pImg4,
    pSImg: pSImg4,
    ssIcon: sIcon6,
    des: "",
    width: "140",
  },
];

export default Projects;
