import React from "react";
import CountUp from "react-countup";

const FunFact = (props) => {
  const funFact = [
    {
      title: "4",
      subTitle: "Ongoing",
      sts: "Projects",
    },
    {
      title: "10000",
      subTitle: "Happy",
      sts: "Clients",
    },
    {
      title: "9",
      subTitle: "Active",
      sts: "Programs",
    },
    {
      title: "1500",
      subTitle: "Team",
      sts: "Members",
    },
  ];

  return (
    <section className={`success-section mb-115 ${props.fClass}`}>
      <div className="container">
        <div className="row">
          {funFact.map((funfact, fitem) => (
            <div className="col-xl-3 col-md-7" key={fitem}>
              <div className="success-box">
                <span className="count-text" data-stop={funfact.title}>
                  <CountUp
                    end={parseInt(funfact.title)}
                    suffix="+"
                    enableScrollSpy
                  />
                </span>
                <div className="success-content">
                  <h3>{funfact.subTitle}</h3>
                  <h6>{funfact.sts}</h6>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <span id="counter" className="d-none" />
    </section>
  );
};

export default FunFact;
