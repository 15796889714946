import React, { useState, useEffect } from "react";
import { Layout, Menu, Drawer, Button } from "antd";
import {
  HomeOutlined,
  InfoCircleOutlined,
  ProjectOutlined,
  AppstoreOutlined,
  ContactsOutlined,
  MenuOutlined,
} from "@ant-design/icons";
import { Link, useLocation } from "react-router-dom";
import Logo from "../../images/commanderbuilders.png";
import "../../css/menu.css";
import "../../css/navbar.css";

const { Header } = Layout;

const Navbar2 = () => {
  const [scroll, setScroll] = useState(0);
  const [visible, setVisible] = useState(false);
  const location = useLocation();

  const handleScroll = () => setScroll(document.documentElement.scrollTop);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const className = scroll > 80 ? "fixed-navbar active" : "fixed-navbar";

  const getSelectedKey = () => {
    if (location.pathname.startsWith("/projects")) return "3";
    if (location.pathname.startsWith("/project-single")) return "3";
    if (location.pathname.startsWith("/our-programs")) return "4";
    if (location.pathname.startsWith("/blog")) return "5";
    if (location.pathname.startsWith("/about")) return "2";
    if (location.pathname.startsWith("/contact")) return "6";
    if (location.pathname.startsWith("/service-single")) return "4";
    if (location.pathname.startsWith("/blog-single")) return "5";
    return "1";
  };

  const showDrawer = () => {
    setVisible(true);
  };

  const onClose = () => {
    setVisible(false);
  };

  return (
    <Header className={className}>
      <div className="logo">
        <Link to="/">
          <img src={Logo} alt="Logo" />
        </Link>
      </div>
      <Button
        className="menuButton"
        type="primary"
        icon={<MenuOutlined />}
        onClick={showDrawer}
      />
      <Drawer
        title="Menu"
        placement="right"
        onClose={onClose}
        visible={visible}
      >
        <Menu
          theme="light"
          mode="vertical"
          selectedKeys={[getSelectedKey()]}
          onClick={onClose}
        >
          <Menu.Item key="1" icon={<HomeOutlined />}>
            <Link to="/">Home</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<InfoCircleOutlined />}>
            <Link to="/about">About Us</Link>
          </Menu.Item>
          <Menu.Item key="3" icon={<ProjectOutlined />}>
            <Link to="/projects">Projects</Link>
          </Menu.Item>
          <Menu.Item key="4" icon={<AppstoreOutlined />}>
            <Link to="/our-programs">Programs & Services</Link>
          </Menu.Item>
          <Menu.Item key="5" icon={<ContactsOutlined />}>
            <Link to="/blog">Vision 2040</Link>
          </Menu.Item>
          {/* <Menu.SubMenu
            key="3"
            title={
              <span className="submenu-title-wrapper">
                <ProjectOutlined />
                <Link to="/projects" className="submenu-title-link">
                  Projects
                </Link>
              </span>
            }
          >
            <Menu.Item key="3-1">
              <Link to="/project-single/Commander-City">Commander City</Link>
            </Menu.Item>
            <Menu.Item key="3-2">
              <Link to="/project-single/Commander-Enclave">
                Commander Enclave
              </Link>
            </Menu.Item>
            <Menu.Item key="3-3">
              <Link to="/project-single/Commander-Heights">
                Commander Heights
              </Link>
            </Menu.Item>
            <Menu.Item key="3-4">
              <Link to="/project-single/Breezy-Commander-Heights">
                Breezy Commander Heights
              </Link>
            </Menu.Item>
          </Menu.SubMenu> */}
          {/* <Menu.SubMenu
            key="4"
            title={
              <span className="submenu-title-wrapper">
                <AppstoreOutlined />
                <Link to="/our-programs" className="submenu-title-link">
                  Our Programs
                </Link>
              </span>
            }
          >
            <Menu.Item key="4-1">
              <Link to="/service-single/Youth-Program">Youth Program</Link>
            </Menu.Item>
            <Menu.Item key="4-2">
              <Link to="/service-single/Commander-City-Industries">
                Commander City Industries
              </Link>
            </Menu.Item>
            <Menu.Item key="4-3">
              <Link to="/service-single/Free-Health-Cards">
                Free Health Cards
              </Link>
            </Menu.Item>
            <Menu.Item key="4-4">
              <Link to="/service-single/Commander-Welfare-Mart">
                Commander Welfare Mart
              </Link>
            </Menu.Item>
            <Menu.Item key="4-5">
              <Link to="/service-single/Free-Restaurant">Free Restaurant</Link>
            </Menu.Item>
            <Menu.Item key="4-6">
              <Link to="/service-single/Building-Futures">
                Building Futures
              </Link>
            </Menu.Item>
            <Menu.Item key="4-7">
              <Link to="/service-single/Drug-Free-Society">
                Drug-Free Society
              </Link>
            </Menu.Item>
            <Menu.Item key="4-8">
              <Link to="/service-single/Special-Charity-Program">
                Special Charity Program
              </Link>
            </Menu.Item>
            <Menu.Item key="4-9">
              <Link to="/service-single/Commander-Welfare-Program">
                Commander Welfare Program
              </Link>
            </Menu.Item>
          </Menu.SubMenu> */}
          {/* <Menu.SubMenu
            key="5"
            title={
              <span className="submenu-title-wrapper">
                <AppstoreOutlined />
                <Link to="/blog" className="submenu-title-link">
                  Vision 2040
                </Link>
              </span>
            }
          >
            <Menu.Item key="5-1">
              <Link to="/blog-single/our-vision">Our Vision</Link>
            </Menu.Item>
            <Menu.Item key="5-2">
              <Link to="/blog-single/milestone-achieved">
                Milestone Achieved
              </Link>
            </Menu.Item>
            <Menu.Item key="5-3">
              <Link to="/blog-single/chairmans-profile">
                Chairman's Profile
              </Link>
            </Menu.Item>
          </Menu.SubMenu> */}
          <Menu.Item key="6" icon={<ContactsOutlined />}>
            <Link to="/contact">Contact Us</Link>
          </Menu.Item>
        </Menu>
      </Drawer>
      <div className="menuDesktop">
        <Menu theme="dark" mode="horizontal" selectedKeys={[getSelectedKey()]}>
          <Menu.Item key="1" icon={<HomeOutlined />}>
            <Link to="/">Home</Link>
          </Menu.Item>
          <Menu.Item key="2" icon={<InfoCircleOutlined />}>
            <Link to="/about">About Us</Link>
          </Menu.Item>
          <Menu.SubMenu
            key="3"
            title={
              <span className="submenu-title-wrapper">
                <ProjectOutlined />
                <Link to="/projects" className="submenu-title-link">
                  Projects
                </Link>
              </span>
            }
          >
            <Menu.Item key="3-1">
              <Link to="/project-single/Commander-City">Commander City</Link>
            </Menu.Item>
            <Menu.Item key="3-2">
              <Link to="/project-single/Commander-Enclave">
                Commander Enclave
              </Link>
            </Menu.Item>
            <Menu.Item key="3-3">
              <Link to="/project-single/Commander-Heights">
                Commander Heights
              </Link>
            </Menu.Item>
            <Menu.Item key="3-4">
              <Link to="/project-single/Breezy-Commander-Heights">
                Breezy Commander Heights
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu
            key="4"
            title={
              <span className="submenu-title-wrapper">
                <AppstoreOutlined />
                <Link to="/our-programs" className="submenu-title-link">
                  Programs & Services
                </Link>
              </span>
            }
          >
            <Menu.Item key="4-1">
              <Link to="/service-single/Youth-Program">Youth Program</Link>
            </Menu.Item>
            <Menu.Item key="4-2">
              <Link to="/service-single/Commander-City-Industries">
                Commander City Industries
              </Link>
            </Menu.Item>
            <Menu.Item key="4-3">
              <Link to="/service-single/Free-Health-Cards">
                Free Health Cards
              </Link>
            </Menu.Item>
            <Menu.Item key="4-4">
              <Link to="/service-single/Commander-Welfare-Mart">
                Commander Welfare Mart
              </Link>
            </Menu.Item>
            <Menu.Item key="4-5">
              <Link to="/service-single/Free-Restaurant">Free Restaurant</Link>
            </Menu.Item>
            <Menu.Item key="4-6">
              <Link to="/service-single/Drug-Free-Society">
                Drug-Free Society
              </Link>
            </Menu.Item>
            <Menu.Item key="4-7">
              <Link to="/service-single/Commander-City-Public-School">
                Commander Public School
              </Link>
            </Menu.Item>
            <Menu.Item key="4-8">
              <Link to="/service-single/Seminars-And-Workshops">
                Seminars & Workshops
              </Link>
            </Menu.Item>
            <Menu.Item key="4-9">
              <Link to="/service-single/Special-Charity-Program">
                Special Charity Program
              </Link>
            </Menu.Item>
            <Menu.Item key="4-10">
              <Link to="/service-single/Safety-And-Security">
                Safety & Security
              </Link>
            </Menu.Item>
            <Menu.Item key="4-11">
              <Link to="/service-single/Sustainable-Development">
                Sustainable Development
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.SubMenu
            key="5"
            title={
              <span className="submenu-title-wrapper">
                <AppstoreOutlined />
                <Link to="/blog" className="submenu-title-link">
                  Vision 2040
                </Link>
              </span>
            }
          >
            <Menu.Item key="5-1">
              <Link to="/blog-single/our-vision">Our Vision</Link>
            </Menu.Item>
            <Menu.Item key="5-2">
              <Link to="/blog-single/milestone-achieved">
                Milestone Achieved
              </Link>
            </Menu.Item>
            <Menu.Item key="5-3">
              <Link to="/blog-single/chairmans-profile">
                Chairman's Profile
              </Link>
            </Menu.Item>
          </Menu.SubMenu>
          <Menu.Item key="6" icon={<ContactsOutlined />}>
            <Link to="/contact">Contact Us</Link>
          </Menu.Item>
        </Menu>
      </div>
    </Header>
  );
};

export default Navbar2;
