import React, { Fragment } from "react";
import Navbar2 from "../../components/Navbar2/Navbar2";
//import PageTitle from "../../components/pagetitle/PageTitle";
import Contactpage from "../../components/Contactpage/Contactpage";
import Scrollbar from "../../components/scrollbar/scrollbar";
import Footer from "../../components/footer/Footer";
import PageTitleContact from "../../components/pagetitle/PageTitleContact";

const ContactPage = () => {
  return (
    <Fragment>
      <Navbar2 hclass={"header-style-2"} />
      <PageTitleContact pageTitle={"Contact Us"} pagesub={"Contact"} />
      <Contactpage />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ContactPage;
