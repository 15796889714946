import React from "react";
//import { Link } from "react-router-dom";
//import Services from "../../api/Services";
import Bg from "../../images/services/service-bg.jpg";

const ServiceSection = (props) => {
  // const ClickHandler = () => {
  //   window.scrollTo(10, 0);
  // };

  return (
    <section
      className={`services-section rpt-100 pb-70 ${props.ptClass}`}
      style={{ backgroundImage: `url(${Bg})` }}
    >
      <div className="container">
        <div className="section-title mb-10">
          <h2>Our Company</h2>
          <br></br>
          <p>
            <span className="thin-ourcompany" style={{ textTransform: "none" }}>
              Welcome to Commander Builders, where integrity, reliability, and
              commitment are the cornerstones of our philosophy. We stand as a
              beacon of excellence in the realms of real estate development,
              architecture, and construction, driven by the principles of
              creativity, innovation, and uncompromising quality. As we continue
              our journey, we are proud to compile a list of remarkable
              accomplishments that testify to our commitment to excellence. Join
              us as we pave the way for a brighter, more sustainable future in
              the world of real estate and construction.
            </span>
          </p>
        </div>
        {/* <div className="row">
          {Services.slice(0, 3).map((service, Sitem) => (
            <div className="col-lg-4 col-md-6 col-12" key={Sitem}>
              <div className="service-item">
                <span className="number">0{service.Id}</span>
                <img src={service.sImg} alt="Icon" />
                <h3>
                  <Link
                    onClick={ClickHandler}
                    to={`/service-single/${service.slug}`}
                  >
                    {service.sTitle}
                  </Link>
                </h3>
                <p>{service.description}</p>
              </div>
            </div>
          ))}
        </div> */}
      </div>
    </section>
  );
};

export default ServiceSection;
