import React, { Fragment } from "react";
import Navbar2 from "../../components/Navbar2/Navbar2";
import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import { useParams } from "react-router-dom";
// import Project from "../../api/Projects";
import Plans from "./Plans";
import Footer from "../../components/footer/Footer";
import Projects from "../../api/Projects";
//import BG from "../../images/apartment/apartment-plan.png";
import A1 from "../../images/apartment/TYPEA1.jpg";
import B1P from "../../images/apartment/TYPEB-PLANTINUM.jpg";
import B1G from "../../images/apartment/TYPEB-GOLD.jpg";
import B1S from "../../images/apartment/TYPEB-SILVER.jpg";
import C1 from "../../images/apartment/TYPEC1.jpg";
import C from "../../images/apartment/TYPEC.jpg";
import XL from "../../images/apartment/TYPEXL.jpg";

import SYD80Single from "../../images/apartment/city/80SYD(Single).png";
import SYD80Double from "../../images/apartment/city/80SYD(Double).png";
import SYD120Single from "../../images/apartment/city/120SYD(Single).png";
import SYD120Double from "../../images/apartment/city/120SYD(Double).png";
import SYD160Single from "../../images/apartment/city/160SYD(Single).png";
import SYD240Single from "../../images/apartment/city/240SYD(Single).png";
import Appartments from "../../images/apartment/city/Apartments.png";

import Rooms3TypeC from "../../images/apartment/heights/Rooms3TypeC.jpg";
import Rooms3TypeC1 from "../../images/apartment/heights/Rooms3TypeC1.jpg";
import Rooms4TypeB from "../../images/apartment/heights/Rooms4TypeB.webp";
import Rooms5TypeA from "../../images/apartment/heights/Rooms5TypeA.webp";
import Rooms5TypeA1 from "../../images/apartment/heights/Rooms5TypeA1.jpeg";

const projectPlansContent = {
  "Commander-City": [
    {
      id: "1",
      title: "80 SYD SINGLE",
      image: SYD80Single,
    },

    {
      id: "2",
      title: "80 SYD DOUBLE",
      image: SYD80Double,
    },

    {
      id: "3",
      title: "120 SYD SINGLE",
      image: SYD120Single,
    },

    {
      id: "4",
      title: "120 SYD DOUBLE",
      image: SYD120Double,
    },

    {
      id: "5",
      title: "160 SYD SINGLE",
      image: SYD160Single,
    },

    {
      id: "6",
      title: "240 SYD SINGLE",
      image: SYD240Single,
    },

    {
      id: "7",
      title: "APPARTMENTS",
      image: Appartments,
    },
    // Add more plans specific to "Commander-City"
  ],

  "Commander-Enclave": [
    {
      id: "1",
      title: "Type A1",
      image: A1,
    },

    {
      id: "2",
      title: "Type B Platinum",
      image: B1P,
    },

    {
      id: "3",
      title: "Type B Gold",
      image: B1G,
    },

    {
      id: "4",
      title: "Type B Silver",
      image: B1S,
    },

    {
      id: "5",
      title: "Type C1",
      image: C1,
    },

    {
      id: "6",
      title: "Type C",
      image: C,
    },

    {
      id: "7",
      title: "Type XL",
      image: XL,
    },

    // Add more plans specific to "Commander-Enclave"
  ],
  "Commander-Heights": [
    {
      id: "1",
      title: "3 Rooms Type C",
      image: Rooms3TypeC,
      // details: [
      //   { label: "Floor", value: "5" },
      //   { label: "Rooms", value: "5" },
      //   { label: "Area m2", value: "920" },
      //   { label: "Parking", value: "5" },
      // ],
      // descriptions: [
      //   "Description 1 for Commander City",
      //   "Description 2 for Commander City",
      // ],
    },

    {
      id: "2",
      title: "3 Rooms Type C1",
      image: Rooms3TypeC1,
      // details: [
      //   { label: "Floor", value: "4" },
      //   { label: "Rooms", value: "4" },
      //   { label: "Area m2", value: "910" },
      //   { label: "Parking", value: "4" },
      // ],
      // descriptions: [
      //   "Description 1 for Architecture Plan 2",
      //   "Description 2 for Architecture Plan 2",
      // ],
    },

    {
      id: "3",
      title: "4 Rooms Type B",
      image: Rooms4TypeB,
      // details: [
      //   { label: "Floor", value: "4" },
      //   { label: "Rooms", value: "4" },
      //   { label: "Area m2", value: "910" },
      //   { label: "Parking", value: "4" },
      // ],
      // descriptions: [
      //   "Description 1 for Architecture Plan 2",
      //   "Description 2 for Architecture Plan 2",
      // ],
    },

    {
      id: "4",
      title: "5 Rooms Type A",
      image: Rooms5TypeA,
      // details: [
      //   { label: "Floor", value: "4" },
      //   { label: "Rooms", value: "4" },
      //   { label: "Area m2", value: "910" },
      //   { label: "Parking", value: "4" },
      // ],
      // descriptions: [
      //   "Description 1 for Architecture Plan 2",
      //   "Description 2 for Architecture Plan 2",
      // ],
    },

    {
      id: "5",
      title: "5 Rooms Type A1",
      image: Rooms5TypeA1,
      // details: [
      //   { label: "Floor", value: "4" },
      //   { label: "Rooms", value: "4" },
      //   { label: "Area m2", value: "910" },
      //   { label: "Parking", value: "4" },
      // ],
      // descriptions: [
      //   "Description 1 for Architecture Plan 2",
      //   "Description 2 for Architecture Plan 2",
      // ],
    },
    // Add more plans specific to "Commander-Heights"
  ],
  "Breezy-Commander-Heights": [
    // {
    //   id: "1",
    //   title: "80 sq yd",
    //   image: BG,
    //   details: [
    //     { label: "Floor", value: "5" },
    //     { label: "Rooms", value: "5" },
    //     { label: "Area m2", value: "920" },
    //     { label: "Parking", value: "5" },
    //   ],
    //   descriptions: [
    //     "Description 1 for Commander City",
    //     "Description 2 for Commander City",
    //   ],
    // },
    // Add more plans specific to "Breezy-Commander-Heights"
  ],
};

const ProjectSinglePage = () => {
  const { slug } = useParams();
  const projectDetails = Projects.find((item) => item.slug === slug);

  if (!projectDetails) {
    return <div>Project not found</div>;
  }

  return (
    <Fragment>
      <Navbar2 />
      <PageTitle pageTitle={projectDetails.pTitle} pagesub={"Project"} />
      <div className="project-single-area section-padding">
        <div className="property-single mt-150">
          {/* <div className="container">
            <div className="row">
              <div className="col-lg-7">
                <div className="property-single-left">
                  <div className="property-left-content">
                    <h3>{projectDetails.pTitle}</h3>
                    <p>{projectDetails.des}</p>
                  </div>
                </div>
              </div>
              <div className="col-lg-5">
                <div className="property-single-right">
                  <div className="property-right-content pl-40 rpl-0">
                    <h3 className="border-text">Project complex</h3>
                    <h3>Project Park</h3>
                    <h3>Project gallery</h3>
                    <h3>Hospital</h3>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
        <Plans plansContent={projectPlansContent[slug]} />
      </div>
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};

export default ProjectSinglePage;
