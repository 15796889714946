import React, { useState } from "react";
import { TabContent, TabPane, Nav, NavItem, NavLink } from "reactstrap";
import classnames from "classnames";

const Plans = ({ plansContent }) => {
  const [activeTab, setActiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) setActiveTab(tab);
  };

  return (
    <section className="apartment-plan text-center mt-135">
      <div className="container">
        <div className="section-title mb-50">
          <h2>
            Apartment <span className="thin">Plans</span>
          </h2>
          {/* <p>At vero eos et accusamus et iusto odio</p> */}
        </div>
        <div className="apartment-tab">
          <ul
            className="nav nav-tabs justify-content-center mb-65"
            role="tablist"
          >
            <Nav tabs>
              {plansContent.map((plan, index) => (
                <NavItem key={index}>
                  <NavLink
                    className={classnames({ active: activeTab === plan.id })}
                    onClick={() => {
                      toggle(plan.id);
                    }}
                  >
                    {plan.title}
                  </NavLink>
                </NavItem>
              ))}
            </Nav>
          </ul>
          <TabContent activeTab={activeTab}>
            {plansContent.map((plan, index) => (
              <TabPane tabId={plan.id} key={index}>
                <h3>{plan.title}</h3>
                <img src={plan.image} alt={plan.title} />
                <div className="row text-left justify-content-center mt-40">
                  {/* <div className="col-md-3">
                    <ul className="apertment-list mr-75 rmr-0">
                      {plan.details.map((detail, idx) => (
                        <li key={idx}>
                          {detail.label} <span>{detail.value}</span>
                        </li>
                      ))}
                    </ul>
                  </div>
                  <div className="col-md-5">
                    <div className="amertment-content">
                      {plan.descriptions.map((desc, idx) => (
                        <p key={idx}>{desc}</p>
                      ))}
                    </div>
                  </div> */}
                </div>
              </TabPane>
            ))}
          </TabContent>
        </div>
      </div>
    </section>
  );
};

export default Plans;
