import React from "react";
import { Link } from "react-router-dom";

const ZindagiBadaldo = (props) => {
  const { pageTitle, pagesub, img, bannerClass } = props;
  return (
    <section
      className={`banner-section-zindagi rmt-70 ${bannerClass}`}
      style={{ backgroundImage: `url(${img})` }}
    >
      <div className="container">
        <div className="banner-inner-new">
          <div className="page-title">
            <h2>{pageTitle}</h2>
            <span>{pagesub}</span>
          </div>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              <li className="breadcrumb-item">
                <Link to="/">Home</Link>
              </li>
              <li className="breadcrumb-item active" aria-current="page">
                {pagesub}
              </li>
            </ol>
          </nav>
        </div>
      </div>
    </section>
  );
};

export default ZindagiBadaldo;
