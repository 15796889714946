import React, { Fragment } from "react";
import Navbar2 from "../../components/Navbar2/Navbar2";
//import PageTitle from "../../components/pagetitle/PageTitle";
import Scrollbar from "../../components/scrollbar/scrollbar";
import ProjectSectionS2 from "../../components/ProjectSectionS2/ProjectSectionS2";
import Footer from "../../components/footer/Footer";
import { Helmet } from "react-helmet";
import PageTitleProjects from "../../components/pagetitle/PageTitleProjects";
//import PageTitleProjects from "../../components/pagetitle/PageTitleProjects";

const ProjectPage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Projects - Commander Builders</title>
        <meta
          name="description"
          content="city, heights, enclave, breezy heights"
        />
        <link rel="canonical" href="http://localhost:3036/projects" />
      </Helmet>
      <Navbar2 />
      <PageTitleProjects pageTitle={"Projects"} pagesub={"Project"} />
      <ProjectSectionS2 />
      <Footer />
      <Scrollbar />
    </Fragment>
  );
};
export default ProjectPage;
