import React from "react";
import ContactForm from "../ContactFrom";
import sIcon1 from "../../images/contact/icon1.png";
import sIcon2 from "../../images/contact/icon2.png";
import sIcon3 from "../../images/contact/icon3.png";

const Contactpage = () => {
  return (
    <div>
      <div className="contact-map" id="map">
        <iframe
          title="Map to our office location"
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3618.2114014407175!2d67.17446871066235!3d24.924867242585588!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb33831e8c9547f%3A0x1f981e7b73d98eb0!2sCommander%20Builders!5e0!3m2!1sen!2sau!4v1717904197211!5m2!1sen!2sau"
          width="600"
          height="450"
          style={{ border: 0 }}
          allowfullscreen=""
          loading="lazy"
          referrerpolicy="no-referrer-when-downgrade"
        ></iframe>
      </div>
      <div className="contact-info text-center mb-110">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-4 col-md-6">
              <div className="info-item">
                <img src={sIcon1} alt="Icon" />
                <p>
                  Commercial 5, Sector 40, Scheme 33, Malir Cantt Area, Karachi,
                  Karachi City, Sindh, Pakistan
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="info-item">
                <img src={sIcon2} alt="Icon" />
                <p>
                  enquiries@commanderbuilders.com <br />
                  +92 333 3330006
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="info-item">
                <img src={sIcon3} alt="Icon" />
                <p>
                  Web Address: <br />
                  commanderbuilders.com
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ContactForm />
    </div>
  );
};

export default Contactpage;
