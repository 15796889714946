import React from "react";
import abimg1 from "../../images/video/video-image.jpg";
import VideoModal from "../ModalVideo";

const VideoSection = (props) => {
  return (
    <div className={`video-section pt-185 ${props.vclass}`}>
      <div className="container">
        <div className="video-inner">
          <img src={abimg1} alt="Video" />
          <VideoModal />
        </div>
      </div>
    </div>
  );
};

export default VideoSection;

// import React, { useState } from "react";
// import abimg1 from "../../images/video/video-image.jpg";

// const VideoSection = (props) => {
//   const [isVideoOpen, setVideoOpen] = useState(false);

//   const handlePlayVideo = () => {
//     setVideoOpen(true);
//   };

//   return (
//     <div className={`video-section pt-185 ${props.vclass}`}>
//       <div className="container">
//         <div className="video-inner">
//           {isVideoOpen ? (
//             <iframe
//               width="560"
//               height="315"
//               src="https://www.youtube.com/embed/16TgusFJwr4"
//               title="YouTube video player"
//               frameBorder="0"
//               allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
//               allowFullScreen
//             ></iframe>
//           ) : (
//             <div className="video-thumbnail" onClick={handlePlayVideo}>
//               <img src={abimg1} alt="Video Thumbnail" />
//               <button className="video-play">
//                 <i className="fa fa-play" aria-hidden="true"></i>
//               </button>
//             </div>
//           )}
//         </div>
//       </div>
//     </div>
//   );
// };

// export default VideoSection;
